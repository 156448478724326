._1wu2xbc1 {
  --grid-column-count: 24;
  --grid-gap: 10px;
  background-color: var(--_18bwoz28);
  padding: 80px 0;
}
._1wu2xbc2 {
  grid-column: 5 / span 16;
  margin-bottom: 40px;
  text-align: center;
}
._1wu2xbc3 {
  --grid-column-count: 3;
  --grid-gap: 20px;
  grid-column: 4 / span 18;
  margin: 0;
}
._1wu2xbc4 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 300px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-decoration: none;
}
._1wu2xbc6 {
  grid-column: 1 / -1;
  text-align: center;
  margin-top: 40px;
}
@media (max-width: 1439px) {
  ._1wu2xbc3 {
    grid-column: 2 / span 22;
  }
}
@media (max-width: 1023px) {
  ._1wu2xbc0 {
    background-color: var(--_18bwoz28);
  }
  ._1wu2xbc1 {
    --grid-gap: 7.5px;
  }
  ._1wu2xbc3 {
    --grid-gap: 7.5px;
    grid-column: 1 / -1;
  }
}
@media (max-width: 767px) {
  ._1wu2xbc1 {
    --grid-column-count: 4;
    padding: 40px 0;
  }
  ._1wu2xbc2 {
    grid-column: 1 / -1;
  }
  ._1wu2xbc3 {
    --grid-column-count: 1;
    gap: 15px;
  }
}