._19u4xoz0 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 40px;
  height: 100%;
  z-index: 2;
}
._19u4xoz1 {
  position: relative;
  justify-content: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  left: 0;
}
._19u4xoz2 {
  width: 140px;
  height: 12px;
}