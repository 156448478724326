._1m0z1oh2 label > div {
  display: none;
}
@media (max-width: 1023px) {
  ._1m0z1oh0 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
  }
  ._1m0z1oh1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
  }
}
@media (min-width: 1024px) {
  ._1m0z1oh2 {
    padding-right: 25%;
  }
}