.d439jh0 {
  --grid-column-count: 12;
  --grid-column-width: calc((100% - max(calc((100vw - 1360px) / 2), 60px) - 60px)/var(--grid-column-count));
  display: flow-root;
  margin: 6.25rem 0;
  max-width: calc(1360px +  calc(calc(100% - 1360px) / 2));
  margin-right: 0;
  overflow: hidden;
  margin-left: max(calc(calc(100vw - 1360px) / 2), 60px);
}
.d439jh1 {
  float: left;
  position: relative;
  width: calc(var(--grid-column-width)*3);
  margin-left: var(--grid-column-width);
  margin-right: var(--grid-column-width);
}
.d439jh2 {
  margin-bottom: 0.6rem;
}
.d439jh3 {
  float: left;
  height: 100%;
  position: relative;
  width: calc(100% - calc(var(--grid-column-width) * 5));
}
.d439jh4 {
  width: 100%;
}
.d439jh4 > div {
  width: calc(100% - max(calc(calc(100vw - 1360px) / 2), 60px));
}
.d439jh5 {
  margin-bottom: 1.25rem;
}
.d439jh6 {
  margin-bottom: 0.25rem;
  white-space: nowrap;
}
.d439jh1 a:only-of-type {
  margin-bottom: 1.25rem;
}
.d439jh6.yjwwhq6 {
  margin-bottom: 0;
}
.d439jh7 {
  aspect-ratio: 483 / 350;
  display: block;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}
.d439jh7 ._1csod971 {
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.d439jh8 {
  background-color: var(--_18bwoz24);
  aspect-ratio: 483 / 350;
  margin-bottom: 20px;
  height: 100%;
  position: relative;
}
.d439jh9 {
  bottom: 0;
  height: 100%;
  left: 0;
  padding: 21px;
  position: absolute;
  right: 0;
  margin: 0;
  top: 0;
}
.d439jha {
  color: var(--_18bwoz2b);
  margin-bottom: 10px;
}
.d439jhb {
  color: var(--_18bwoz21);
}
.d439jhd {
  color: var(--_18bwoz20);
  font-weight: 600;
  padding-right: 0.5rem;
  text-decoration: none;
}
.d439jhe {
  --grid-gap: 20px;
  --slide-margin: calc(var(--grid-gap) / 2);
}
.d439jhe .carousel__inner-slide {
  width: calc(100% - var(--slide-margin) * 2);
  margin: 0 var(--slide-margin);
}
.d439jhf {
  color: var(--_18bwoz22);
  margin-bottom: 0.6rem;
}
.d439jhg {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.d439jh0 ._1ykw5bl2 {
  top: min(50%, 145px);
}
.d439jh0 ._1ykw5bl2:last-child {
  right: 2.5rem;
}
@media (max-width: 1023px) {
  .d439jh0 {
    margin-left: max(calc(calc(100vw - 1360px) / 2), 40px);
  }
  .d439jh1 {
    margin-left: 0;
    width: calc(var(--grid-column-width)*5);
  }
  .d439jh3 {
    width: calc(100% - calc(var(--grid-column-width) * 6));
  }
  .d439jhe {
    --grid-gap: 15px;
  }
}
@media (max-width: 767px) {
  .d439jh0 {
    margin: 4.5rem 0 3rem max(calc(calc(100vw - 1360px) / 2), 15px);
  }
  .d439jh1 {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-bottom: 1.5rem;
    width: calc(100% - max(calc(calc(100vw - 1360px) / 2), 15px));
  }
  .d439jh3 {
    width: 75%;
  }
  .d439jh4 {
    width: 133%;
  }
  .d439jh4 > div {
    width: 75%;
  }
  .d439jh5 {
    text-align: center;
  }
  .d439jh0 ._1ykw5bl2:last-child {
    right: -30%;
  }
}
@supports not (aspect-ratio: 483 / 350) {
  .d439jh7 {
    height: 100%;
    padding-top: calc(350/483 * 100%);
  }
  .d439jh8 {
    padding-top: calc(350/483 * 100%);
  }
}
@media (max-width: 1439px) {
  .d439jh0 ._1ykw5bl2 {
    top: min(50%, 145px);
  }
}
@media (max-width: 479px) {
  .d439jh0 ._1ykw5bl2 {
    top: min(50%, 95px);
  }
  .d439jh0 ._1ykw5bl2:last-child {
    right: -75px;
  }
}