.at98jn0 {
  --header-content-column: 2 / span 4;
  --body-content-column: 6 / span 6;
  --body-column-order: unset;
  --header-text-align: left;
  margin-bottom: 80px;
  margin-top: 80px;
}
.at98jn1 {
  --header-content-column: 8 / span 4;
  --body-content-column: 2 / span 6;
  --body-column-order: -1;
  --header-text-align: right;
}
.at98jn0 .u3zewj0 * {
  margin: 0;
}
.at98jn2 {
  grid-column: var(--header-content-column);
  margin: 0;
  text-align: var(--header-text-align);
}
.at98jn3 {
  grid-column: var(--body-content-column);
  order: var(--body-column-order);
}
.at98jn5 {
  margin-top: 20px;
}
.at98jn6 {
  margin-right: 2rem;
}
@media (max-width: 767px) {
  .at98jn0 {
    --header-content-column: 1 / -1;
    --body-content-column: 1 / -1;
  }
  .at98jn1 {
    --body-column-order: unset;
    --header-text-align: left;
  }
  .at98jn2 {
    padding-bottom: 40px;
  }
}
@media (max-width: 1023px) {
  .at98jn6 {
    margin-top: 20px;
  }
  .at98jn6:first-child {
    margin-top: 0;
  }
}