._12q8kh11 {
  height: 100%;
  position: relative;
  min-width: 65vw;
}
._12q8kh12 {
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  pointer-events: none;
  margin: 0 max(calc(calc(100vw - 1360px) / 2), 60px);
  top: 0;
}
._12q8kh15 {
  transform: rotate(180deg);
}
._12q8kh17 {
  display: block;
  height: calc(100vh - 48px);
  position: relative;
  width: 100%;
}
._12q8kh17 ._1csod971 {
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  right: 0;
  width: 100%;
}
._12q8kh18 {
  --grid-column-count: 24;
  --grid-gap: 10px;
  display: grid;
  grid-column-gap: var(--grid-gap);
  grid-template-columns: repeat(var(--grid-column-count), minmax(0, 1fr));
  margin: 0 max(calc(calc(100vw - 1360px) / 2), 60px);
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}
._12q8kh19 {
  --caption-span-default: 8;
  --caption-span-below-large: 12;
  --caption-span-below-medium: 14;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-column: 3 / span var(--caption-span-default);
}
._12q8kh19 > h2 {
  font-family: var(--_1ttbmbv2);
  font-size: var(--_1ttbmbve);
  line-height: var(--_1ttbmbv13);
  letter-spacing: var(--_1ttbmbv1t);
  margin-bottom: 10px;
}
._12q8kh19 .u3zewj0 * {
  font-family: var(--_1ttbmbv6);
  font-size: var(--_1ttbmbvi);
  line-height: var(--_1ttbmbv17);
  letter-spacing: var(--_1ttbmbv1x);
  margin: 0;
}
._12q8kh19 .wjlq0t3 {
  margin-top: 20px;
}
._12q8kh1b .wjlq0t4 ._12q8kh19 {
  grid-column: 9 / span var(--caption-span-default);
}
._12q8kh1b .wjlq0t5 {
  text-align: left;
}
._12q8kh1b .wjlq0t5 ._12q8kh19 {
  grid-column: 16 / span var(--caption-span-default);
}
@media (max-width: 1439px) {
  ._12q8kh19 {
    grid-column: 3 / span var(--caption-span-below-large);
  }
  ._12q8kh1b .wjlq0t4 ._12q8kh19 {
    grid-column: 7 / span var(--caption-span-below-large);
  }
  ._12q8kh1b .wjlq0t5 ._12q8kh19 {
    grid-column: 12 / span var(--caption-span-below-large);
  }
}
@media (max-width: 1023px) {
  ._12q8kh12 {
    margin: 0 max(calc(calc(100vw - 1360px) / 2), 40px);
  }
  ._12q8kh18 {
    --grid-gap: 7.5px;
    margin: 0 max(calc(calc(100vw - 1360px) / 2), 40px);
  }
  ._12q8kh19 {
    grid-column: 3 / span var(--caption-span-below-medium);
  }
  ._12q8kh19 > h2 {
    font-size: var(--_1ttbmbvr);
    line-height: var(--_1ttbmbv1g);
  }
  ._12q8kh1b .wjlq0t4 ._12q8kh19 {
    grid-column: 6 / span var(--caption-span-below-medium);
  }
  ._12q8kh1b .wjlq0t5 ._12q8kh19 {
    grid-column: 10 / span var(--caption-span-below-medium);
  }
}
@media (max-width: 767px) {
  ._12q8kh12 {
    margin: 0 max(calc(calc(100vw - 1360px) / 2), 15px);
  }
  ._12q8kh17 {
    height: calc(100vh - 42px);
  }
  ._12q8kh18 {
    display: block;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
  }
  ._12q8kh19 {
    grid-column: 1 / -1;
  }
  ._12q8kh19 > h2 {
    font-size: var(--_1ttbmbvx);
    line-height: var(--_1ttbmbv1g);
  }
  ._12q8kh1b .wjlq0t5 {
    text-align: center;
  }
}