._1185dtz0 {
  margin-top: 40px;
  margin-bottom: 40px;
}
._1185dtz1 {
  margin-bottom: 80px;
}
._1185dtz2 {
  grid-column: 1 / span 9;
  padding-right: 30px;
}
._1185dtz3 {
  grid-column: 10 / span 3;
}
._1185dtz4 {
  margin-bottom: 20px;
}
._1185dtz5 p {
  min-height: 27px;
}
._1185dtz6 {
  color: var(--_18bwoz26);
  font-family: Nexa-Light;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  text-decoration: none;
}
._1185dtz6:visited {
  color: var(--_18bwoz26);
}
._1185dtz7 {
  display: inline-block;
  fill: var(--_18bwoz26);
  margin-right: 8px;
  width: 8px;
}
._1185dtz8 {
  margin-bottom: 10px;
}
._1185dtz9 {
  margin-bottom: 1.25rem;
  border-bottom: 1px solid var(--_18bwoz26);
  padding-bottom: 1.25rem;
}
._1185dtza {
  text-decoration: none;
}
@media (max-width: 767px) {
  ._1185dtz1 {
    margin-bottom: 40px;
  }
  ._1185dtz2 {
    grid-column: 1 / -1;
    padding-right: 0;
  }
  ._1185dtz3 {
    grid-column: 1 / -1;
    margin-top: 40px;
  }
}