.vwiizv0 {
  --base-tile-margin: 10px;
  padding: 80px 0;
  position: relative;
  margin: 0 calc(max(calc(calc(100vw - 1360px) / 2), 60px) - var(--base-tile-margin));
}
.vwiizv0:last-child {
  border-bottom: none;
}
.vwiizv0:after {
  content: "";
  border-bottom: 1px solid var(--_18bwoz26);
  bottom: 0;
  display: block;
  position: absolute;
  margin: 0 calc(var(--base-tile-margin) / 2);
  width: calc(100% - var(--base-tile-margin));
}
.vwiizv0:last-child:after {
  display: none;
}
.vwiizv1 {
  margin: 0;
}
.vwiizv2 {
  align-items: start;
  display: flex;
  justify-content: space-between;
  margin: 0 var(--base-tile-margin) 40px;
}
.vwiizv3 ._1ykw5bl2 {
  margin: 0;
}
.vwiizv4 {
  margin: 0 var(--base-tile-margin);
}
.vwiizv6 {
  aspect-ratio: 1 / 1;
  display: block;
  position: relative;
  margin-bottom: 10px;
  width: calc(100% - var(--base-tile-margin) * 2);
}
.vwiizv7 {
  background: var(--_18bwoz23);
  bottom: 0;
  color: var(--_18bwoz21);
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  padding: 20px;
  position: absolute;
  right: 0;
  margin: 0;
  top: 0;
}
.vwiizv8 {
  aspect-ratio: 1 / 1;
  display: block;
  position: relative;
  margin-bottom: 10px;
  width: 100%;
}
.vwiizv8 ._1csod971 {
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  right: 0;
  top: 0;
}
.vwiizv9 {
  color: var(--_18bwoz2b);
}
.vwiizvb {
  flex-grow: 1;
}
.vwiizvc a {
  margin: 0 var(--base-tile-margin);
}
.vwiizvd {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.vwiizve.rhap_container {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}
.vwiizve.rhap_container .rhap_main-controls-button,
     .vwiizve.rhap_container .rhap_time {
  color: var(--_18bwoz21);
}
.vwiizve.rhap_container .rhap_progress-indicator {
  background-color: var(--_18bwoz21);
  box-shadow: none;
}
@media (max-width: 1023px) {
  .vwiizv0 {
    --base-tile-margin: 7.5px;
    margin: 0 calc(max(calc(calc(100vw - 1360px) / 2), 40px) - var(--base-tile-margin));
  }
}
@media (max-width: 767px) {
  .vwiizv0 {
    padding: 40px 0;
    margin: 0 calc(max(calc(calc(100vw - 1360px) / 2), 15px) - var(--base-tile-margin));
  }
}
@supports not (aspect-ratio: 1 / 1) {
  .vwiizv6 {
    padding-top: calc(1/1 * 100%);
  }
  .vwiizv8 {
    padding-top: calc(1/1 * 100%);
  }
}