._1t1cbw60 {
  position: relative;
  width: 100%;
  min-height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
._1t1cbw61 {
  display: block;
}
._1t1cbw62 {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
._1t1cbw63 {
  align-items: flex-start;
  margin-top: 2rem;
}
._1t1cbw64 {
  align-items: center;
}
._1t1cbw65 {
  align-items: flex-end;
  margin-bottom: 2rem;
}
._1t1cbw61 ._1csod971 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
._1t1cbw66 {
  display: block;
  width: 50%;
  max-width: 350px;
  margin: 1rem auto;
  margin-left: 0;
  flex-shrink: 0;
}
._1t1cbw68 {
  padding: 0;
}
._1t1cbw69 {
  max-width: 50%;
  flex-grow: 1;
  display: flex;
  align-items: center;
}
._1t1cbw6a {
  align-self: center;
}
._1t1cbw6b {
  align-self: flex-end;
}
._1t1cbw6c {
  align-items: flex-start;
  margin-top: 2rem;
}
._1t1cbw6d {
  align-items: middle;
}
._1t1cbw6e {
  align-items: flex-end;
  margin-bottom: 2rem;
}
._1t1cbw6f {
  width: 100%;
  margin-top: 3rem;
}
._1t1cbw6g {
  align-self: center;
}
._1t1cbw6h {
  align-self: flex-end;
}
._1t1cbw6i {
  margin-left: auto;
}
._1t1cbw6j {
  margin-left: auto;
  margin-right: 0;
}
._1t1cbw6k {
  width: 100%;
  fill: var(--_18bwoz21);
  flex-shrink: 0;
  margin-top: 2rem;
}
._1t1cbw6l {
  fill: var(--_18bwoz21);
}
._1t1cbw6m {
  fill: var(--_18bwoz2b);
}
._1t1cbw6l {
  fill: var(--_18bwoz21);
}
._1t1cbw6m path {
  fill: url(#gold-gradient);
}
@media (max-width: 1023px) {
  ._1t1cbw69 {
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  ._1t1cbw6f {
    display: none;
  }
}
@media (max-width: 767px) {
  ._1t1cbw60 {
    min-height: 0;
    aspect-ratio: 1;
  }
  ._1t1cbw69 {
    display: none;
  }
  ._1t1cbw6f {
    font-size: var(--_1ttbmbvw);
    line-height: var(--_1ttbmbv1l);
    margin-bottom: 3rem;
  }
  @supports not (aspect-ratio: 1) {
    ._1t1cbw60 {
      padding-top: 50%;
    }
  }
}