._1rv3z9t0 {
  position: relative;
}
._1rv3z9t1 {
  --top-position: 80px;
  position: absolute;
  left: 0;
  top: var(--top-position);
  width: calc(100% - max(calc(calc(100vw - 1360px) / 2), 60px) * 2);
}
._1rv3z9t3 {
  top: 50%;
  transform: translateY(-50%);
}
._1rv3z9t4 {
  top: 50%;
  transform: translateY(-50%);
}
._1rv3z9t5 {
  grid-column: 1 / -1;
  text-align: center;
  width: 100%;
}
._1rv3z9t8 {
  aspect-ratio: 1440 / 800;
  display: block;
  position: relative;
  max-height: 800px;
  width: 100%;
  z-index: -1;
}
._1rv3z9t2 ._1rv3z9t5 {
  text-align: left;
}
._1rv3z9t3 ._1rv3z9t5 {
  text-align: left;
}
._1rv3z9t8 ._1csod971 {
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}
@media (max-width: 1439px) {
  ._1rv3z9t1 {
    top: calc(var(--top-position) * 0.8);
    width: calc(100% - max(calc(calc(100vw - 1360px) / 2), 60px)  * 2);
  }
  ._1rv3z9t3 {
    top: 50%;
  }
  ._1rv3z9t4 {
    top: 50%;
  }
}
@media (max-width: 1023px) {
  ._1rv3z9t1 {
    top: calc(var(--top-position) * 0.7);
    width: calc(100% - max(calc(calc(100vw - 1360px) / 2), 40px) * 2);
  }
  ._1rv3z9t3 {
    top: 50%;
  }
  ._1rv3z9t4 {
    top: 50%;
  }
}
@media (max-width: 767px) {
  ._1rv3z9t1 {
    position: static;
    transform: unset;
    width: calc(100% - max(calc(calc(100vw - 1360px) / 2), 15px) * 2);
  }
  ._1rv3z9t3 {
    top: 50%;
  }
  ._1rv3z9t4 {
    top: 50%;
  }
  ._1rv3z9t5 {
    padding-top: 40px;
    margin-bottom: 40px;
  }
  ._1rv3z9t6 {
    color: var(--_18bwoz20);
  }
  ._1rv3z9t7 {
    color: var(--_18bwoz20);
  }
  ._1rv3z9t2 ._1rv3z9t5 {
    text-align: center;
  }
  ._1rv3z9t3 ._1rv3z9t5 {
    text-align: center;
  }
}
@supports not (aspect-ratio: 1440 / 800) {
  ._1rv3z9t8 {
    height: 100%;
    padding-top: min(calc(800/1440 * 100%), 800px);
  }
}