._1sm5pq0 {
  padding: 80px 0;
}
._1sm5pq2 {
  text-align: center;
}
._1sm5pq3 {
  display: flex;
  justify-content: center;
  grid-column: 3 / span 8;
  margin: 0 var(--grid-column-width);
  width: calc(100% - calc(var(--grid-column-width) * 2));
}
._1sm5pq3  * {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
._1sm5pq4 {
  color: var(--_18bwoz22);
  font-size: 40px;
  position: relative;
}
._1sm5pq4:first-child {
  right: 0;
}
._1sm5pq4:last-child {
  left: 0;
  align-self: flex-end;
}
._1sm5pq5 {
  padding: 0 var(--grid-gap);
}
._1sm5pq6 {
  display: inline;
}
._1sm5pq7 {
  display: inline;
}
._1sm5pq8 {
  display: inline;
}
._1sm5pq9 {
  grid-column: 3 / span 8;
  margin-top: 1.5rem;
}
@media (max-width: 767px) {
  ._1sm5pq3 {
    grid-column: 1 / -1;
  }
  ._1sm5pq9 {
    grid-column: 1 / -1;
  }
}
@media (max-width: 479px) {
  ._1sm5pq3 {
    margin: 0;
    width: 100%;
  }
  ._1sm5pq4:first-child {
    margin-right: 0.5rem;
  }
}