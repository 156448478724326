._1p4rt0q0 {
  border-bottom: 1px solid var(--_18bwoz26);
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
._1p4rt0q1 {
  list-style: none;
  padding-left: 0;
  display: flex;
  gap: 80px;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 23px;
  margin-bottom: 23px;
}
._1p4rt0q3 {
  color: var(--_18bwoz20);
  text-decoration: none;
  white-space: nowrap;
}
._1p4rt0q3:visited {
  color: var(--_18bwoz20);
}
._1p4rt0q4 {
  font-family: NunitoSans-Light;
}
._1p4rt0q5 {
  font-family: NunitoSans-Regular;
  text-decoration: underline;
}
@media (max-width: 1439px) {
  ._1p4rt0q1 {
    gap: 60px;
  }
}
@media (max-width: 1023px) {
  ._1p4rt0q1 {
    gap: 30px;
  }
}
@media (max-width: 767px) {
  ._1p4rt0q1 {
    flex-wrap: nowrap;
    justify-content: start;
  }
}