@font-face {
  src: local("Helvetica");
  font-family: Helvetica;
}
@font-face {
  src: url("../static/fonts/Nexa-Bold.woff2") format("woff2"),
          url("../static/fonts/Nexa-Bold.woff") format("woff");
  font-family: Nexa-Bold;
}
@font-face {
  src: url("../static/fonts/Nexa-Light.woff2") format("woff2"),
          url("../static/fonts/Nexa-Light.woff") format("woff");
  font-family: Nexa-Light;
}
@font-face {
  src: url("../static/fonts/NunitoSans-Light.woff2") format("woff2"),
          url("../static/fonts/NunitoSans-Light.woff") format("woff");
  font-family: NunitoSans-Light;
}
@font-face {
  src: url("../static/fonts/NunitoSans-Regular.woff2") format("woff2"),
          url("../static/fonts/NunitoSans-Regular.woff") format("woff");
  font-family: NunitoSans-Regular;
}
* {
  box-sizing: border-box;
}
:where(body, p) {
  font-family: var(--_1ttbmbv6);
  font-size: var(--_1ttbmbvi);
  font-weight: var(--_1ttbmbv29);
  line-height: var(--_1ttbmbv17);
  letter-spacing: var(--_1ttbmbv1x);
  margin: 0;
}
:where(h1) {
  font-family: var(--_1ttbmbv0);
  font-size: var(--_1ttbmbvc);
  font-weight: var(--_1ttbmbv23);
  line-height: var(--_1ttbmbv11);
  letter-spacing: var(--_1ttbmbv1r);
  text-transform: var(--_1ttbmbv2f);
  margin: 0 0 12px;
}
:where(h2) {
  font-family: var(--_1ttbmbv1);
  font-size: var(--_1ttbmbvd);
  font-weight: var(--_1ttbmbv24);
  line-height: var(--_1ttbmbv12);
  letter-spacing: var(--_1ttbmbv1s);
  text-transform: var(--_1ttbmbv2g);
  margin: 0 0 10px;
}
:where(h3) {
  font-family: var(--_1ttbmbv2);
  font-size: var(--_1ttbmbve);
  font-weight: var(--_1ttbmbv25);
  line-height: var(--_1ttbmbv13);
  letter-spacing: var(--_1ttbmbv1t);
  text-transform: var(--_1ttbmbv2h);
  margin: 0 0 8px;
}
:where(h4) {
  font-family: var(--_1ttbmbv3);
  font-size: var(--_1ttbmbvf);
  font-weight: var(--_1ttbmbv26);
  line-height: var(--_1ttbmbv14);
  letter-spacing: var(--_1ttbmbv1u);
  text-transform: var(--_1ttbmbv2i);
  margin: 0 0 8px;
}
:where(h5) {
  font-family: var(--_1ttbmbv4);
  font-size: var(--_1ttbmbvg);
  font-weight: var(--_1ttbmbv27);
  line-height: var(--_1ttbmbv15);
  letter-spacing: var(--_1ttbmbv1v);
  text-transform: var(--_1ttbmbv2j);
  margin: 0 0 8px;
}
:where(h6) {
  font-family: var(--_1ttbmbv5);
  font-size: var(--_1ttbmbvh);
  font-weight: var(--_1ttbmbv28);
  line-height: var(--_1ttbmbv16);
  letter-spacing: var(--_1ttbmbv1w);
  text-transform: var(--_1ttbmbv2k);
  margin: 0;
}
@media (max-width: 1023px) {
  :where(h1) {
    font-size: var(--_1ttbmbvp);
    line-height: var(--_1ttbmbv1e);
  }
  :where(h2) {
    font-size: var(--_1ttbmbvq);
    line-height: var(--_1ttbmbv1f);
  }
  :where(h3) {
    font-size: var(--_1ttbmbvr);
    line-height: var(--_1ttbmbv1g);
  }
  :where(h5) {
    font-size: var(--_1ttbmbvs);
    line-height: var(--_1ttbmbv1h);
  }
  :where(h6) {
    font-size: var(--_1ttbmbvt);
    line-height: var(--_1ttbmbv1i);
  }
}
@media (max-width: 767px) {
  :where(h1) {
    font-size: var(--_1ttbmbvv);
    line-height: var(--_1ttbmbv1k);
  }
  :where(h2) {
    font-size: var(--_1ttbmbvw);
    line-height: var(--_1ttbmbv1l);
  }
  :where(h3) {
    font-size: var(--_1ttbmbvx);
    line-height: var(--_1ttbmbv1m);
  }
  :where(h5) {
    font-size: var(--_1ttbmbvy);
    line-height: var(--_1ttbmbv1o);
  }
  :where(h6) {
    font-size: var(--_1ttbmbvz);
    line-height: var(--_1ttbmbv1p);
  }
}