.a1dkg00 {
  background-color: var(--_18bwoz27);
  overflow: hidden;
  padding: 6.25rem 0;
}
.a1dkg02 {
  position: relative;
}
.a1dkg03 {
  grid-column: 2 / span 10;
  grid-row-gap: unset;
  margin-bottom: 78px;
  text-align: center;
}
.a1dkg06 {
  position: relative;
  width: 100%;
}
.a1dkg07 {
  position: relative;
}
.a1dkg08 {
  aspect-ratio: 1 / 1;
  cursor: pointer;
  display: block;
  position: relative;
  max-height: 554px;
  max-width: 554px;
  width: 100%;
}
.a1dkg08 ._1csod971 {
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  right: 0;
  top: 0;
}
.a1dkg09 {
  display: flex;
  justify-content: center;
  margin-top: 22px;
  z-index: 1;
}
.a1dkg0a {
  height: 50px;
  width: 50px;
}
.a1dkg0a ._1mn7yhj1 {
  display: block;
  width: 100%;
}
.a1dkg0a ._1mn7yhj2 {
  fill: var(--_18bwoz2d);
}
.a1dkg0a ._1mn7yhj5 {
  fill: var(--_18bwoz2c);
}
.a1dkg0a ._1mn7yhj3 {
  fill: var(--_18bwoz2f);
}
.a1dkg0a ._1mn7yhj4 {
  fill: var(--_18bwoz2e);
}
.a1dkg0b {
  bottom: 0%;
  color: var(--_18bwoz21);
  left: 1rem;
  margin-bottom: 1rem;
  position: absolute;
  width: 80%;
}
.a1dkg0b.u3zewj0 p {
  font-family: var(--_1ttbmbv7);
  font-size: var(--_1ttbmbvj);
  letter-spacing: var(--_1ttbmbv1y);
  line-height: var(--_1ttbmbv18);
}
.a1dkg0b.u3zewj0 a {
  color: inherit;
}
.a1dkg0c {
  display: none;
}
.a1dkg0d {
  left: 50%;
  transform: translateX(-50%);
  max-width: 1670px;
  width: calc(100% + 20px);
}
.a1dkg0f {
  top: 40%;
  transform: translateX(2.5rem);
}
.a1dkg0g {
  top: 40%;
  transform: translateX(-2.5rem);
}
.a1dkg0i {
  transform: rotate(180deg);
}
.a1dkg0e .carousel__inner-slide {
  width: calc(100% - 20px);
  margin: 0 10px;
}
@media (max-width: 767px) {
  .a1dkg00 {
    padding: 40px 0;
  }
  .a1dkg02 {
    margin: 0 calc(max(calc(calc(100vw - 1360px) / 2), 15px) - 10px);
  }
  .a1dkg03 {
    grid-column: 1 / span 12;
    margin: 0 1rem 3rem;
  }
  .a1dkg0b {
    width: 100%;
    left: 0.5rem;
  }
  .a1dkg0d {
    left: unset;
    transform: unset;
    width: 100%;
  }
  .a1dkg0f {
    transform: translateX(1rem);
  }
  .a1dkg0g {
    transform: translateX(-1rem);
  }
}
@supports not (aspect-ratio: 1 / 1) {
  .a1dkg08 {
    padding-top: calc(1/1 * 100%);
  }
}