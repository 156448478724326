.ccchs10 {
  position: relative;
}
.ccchs11 {
  margin-top: 80px;
  margin-bottom: 100px;
}
.ccchs12 {
  position: absolute;
  top: 30px;
  left: 0;
  text-transform: uppercase;
}
.ccchs13 {
  display: inline-block;
  fill: var(--_18bwoz21);
  margin-right: 8px;
  width: 10px;
}
.ccchs14 {
  color: var(--_18bwoz21);
  font-family: Nexa-Light;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.15em;
  text-decoration: none;
}
.ccchs14:visited {
  color: var(--_18bwoz21);
}
.ccchs15 {
  grid-column: 2 / span 4;
}
.ccchs15 .yjwwhq6 {
  display: inline-block;
  height: unset;
  padding-top: 0;
  padding-bottom: 0;
}
.ccchs16 {
  grid-column: 6 / span 6;
}
.ccchs16 * {
  font-family: NunitoSans-Regular;
  font-size: 24px;
  line-height: 32px;
  min-height: 32px;
}
.ccchs17 {
  margin: 0;
}
.ccchs18 {
  margin-bottom: 20px;
}
.ccchs19 {
  background-color: var(--_18bwoz23);
}
.ccchs1a {
  display: flex;
}
.ccchs1b {
  align-items: center;
  display: flex;
  padding: 32px 0;
  width: 50%;
}
.ccchs1d {
  border-left: 1px solid var(--_18bwoz21);
  justify-content: end;
}
.ccchs1e {
  align-items: center;
  color: var(--_18bwoz21);
  display: flex;
  line-height: 32px;
  text-decoration: none;
  text-transform: uppercase;
}
.ccchs1e:visited {
  color: var(--_18bwoz21);
}
.ccchs1f {
  padding-left: 11px;
  padding-right: 8px;
}
.ccchs1g {
  justify-content: end;
  padding-left: 8px;
  padding-right: 11px;
  text-align: right;
}
.ccchs1h {
  fill: var(--_18bwoz21);
  height: 15px;
  width: 15px;
}
.ccchs1i {
  margin-right: 11px;
  transform: rotate(180deg);
}
.ccchs1j {
  margin-left: 11px;
}
.ccchs10 ._1t1cbw60 {
  z-index: -1;
}
@media (max-width: 767px) {
  .ccchs15 {
    grid-column: 1 / -1;
  }
  .ccchs16 {
    grid-column: 1 / -1;
  }
  .ccchs16 * {
    font-size: 18px;
    line-height: 27px;
    min-height: 27px;
  }
}