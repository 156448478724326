.rw5ov00 {
  overflow-x: hidden;
}
.rw5ov01 {
  padding: 0;
}
.rw5ov02 {
  grid-row-gap: unset;
  padding: 6.25rem 0;
}
.rw5ov05 {
  padding: 0;
  margin-block: 6.25rem;
}
.rw5ov02 > * {
  grid-column: 4 / span 6;
}
.rw5ov00 .rw5ov03 > * {
  grid-column: 3 / span 8;
}
.rw5ov00 .rw5ov03 > h2 {
  font-family: var(--_1ttbmbv0);
  font-size: var(--_1ttbmbvc);
  font-weight: var(--_1ttbmbv23);
  line-height: var(--_1ttbmbv11);
  letter-spacing: var(--_1ttbmbv1r);
  text-transform: var(--_1ttbmbv2f);
  margin: 0 0 12px;
}
.rw5ov00 .rw5ov03 .wjlq0t1 {
  margin-block: 0;
}
.rw5ov00 .rw5ov04 > * {
  grid-column: 3 / span 8;
}
.rw5ov00 .rw5ov04 .wjlq0t1 {
  margin-block: 0;
}
.rw5ov00 .rw5ov04 .wjlq0t2 * {
  font-family: var(--_1ttbmbv9);
  font-size: var(--_1ttbmbvl);
  line-height: var(--_1ttbmbv1a);
  letter-spacing: var(--_1ttbmbv20);
}
.rw5ov02 > h2 {
  font-family: var(--_1ttbmbv2);
  font-size: var(--_1ttbmbve);
  line-height: var(--_1ttbmbv13);
  margin-bottom: 1.25rem;
}
.rw5ov02 > div:last-child {
  margin-top: 20px;
}
@media (max-width: 1023px) {
  .rw5ov00 .rw5ov03 > h2 {
    font-size: var(--_1ttbmbvp);
    line-height: var(--_1ttbmbv1e);
  }
  .rw5ov00 .rw5ov04 .wjlq0t2 * {
    font-size: var(--_1ttbmbvu);
    line-height: var(--_1ttbmbv1j);
  }
}
@media (max-width: 767px) {
  .rw5ov02 {
    padding: 3rem 0;
  }
  .rw5ov05 {
    margin-block: 3rem;
  }
  .rw5ov02 > * {
    grid-column: 1 / -1;
  }
  .rw5ov00 .rw5ov03 > * {
    grid-column: 1 / -1;
  }
  .rw5ov00 .rw5ov03 > h2 {
    font-size: var(--_1ttbmbvv);
    line-height: var(--_1ttbmbv1k);
  }
  .rw5ov00 .rw5ov03 .wjlq0t1 {
    font-size: var(--_1ttbmbvw);
    line-height: var(--_1ttbmbv1l);
  }
  .rw5ov00 .rw5ov04 > * {
    grid-column: 1 / -1;
  }
  .rw5ov00 .rw5ov04 .wjlq0t2 * {
    font-size: var(--_1ttbmbvu);
    line-height: var(--_1ttbmbv1q);
  }
  .rw5ov02 .wjlq0t3 {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .rw5ov02 .wjlq0t3 a {
    margin-right: 0;
  }
  .rw5ov02 > h2 {
    font-size: var(--_1ttbmbvw);
    line-height: var(--_1ttbmbv1l);
  }
}