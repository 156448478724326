.v8mf6o0 {
  display: none;
  position: fixed;
  background-color: rgba(12, 30, 48, 0.5);
  top: 48px;
  left: 0;
  width: 100vw;
  height: 100vw;
  z-index: -2;
  user-select: none;
  pointer-events: none;
}
.yls3ux5:not(.yls3ux6) li:focus-within > *,
     .yls3ux5:not(.yls3ux6) li:hover > * {
  display: block;
}
.v8mf6o1 {
  display: none;
  position: absolute;
  left: 0;
  width: 100%;
  background-color: var(--_18bwoz21);
  box-shadow: inset 1px 0 0 1px var(--_18bwoz27);
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.v8mf6o2 {
  padding: 50px 0 80px;
  cursor: pointer;
}
.v8mf6o2 > *:nth-child(1) {
  grid-column: 2 / span 3;
}
.v8mf6o2 > *:nth-child(2) {
  grid-column: 6 / span 3;
}
.v8mf6o2 > *:nth-child(3) {
  grid-column: 9 / span 3;
}
.v8mf6o2 a, .v8mf6o2 button {
  pointer-events: auto;
}
.v8mf6o4 {
  display: none;
}
.v8mf6o3 > * {
  display: block;
  height: 100%;
}
:where(.v8mf6o5 a) {
  display: inline-block;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
}
.v8mf6o5 a {
  height: 3.5em;
  padding-top: 1em;
  padding-bottom: 1em;
  font-family: Nexa-Bold;
  font-size: calc(1em - 2px);
  font-weight: 600;
  letter-spacing: 0.2em;
  line-height: 1.75em;
  text-transform: uppercase;
  padding-right: 1.75em;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 14'%3E%3Cpolygon points='10.3 0.9 9 2.2 12.9 6.2 0 6.2 0 8.1 12.9 8.1 9 12 10.3 13.3 16.4 7.1 '%3E%3C/polygon%3E%3C/svg%3E");
  background-size: 17px 14px;
  background-repeat: no-repeat;
  background-position: center right;
  transition: padding-right 0.2s;
}
.v8mf6o5 a:hover {
  padding-right: 2.375em;
}
@media (min-width: 768px) {
  .v8mf6o2 {
    pointer-events: none;
  }
  .v8mf6o2::before {
    content: "";
    position: absolute;
    background-color: var(--_18bwoz27);
    top: 0;
    left: 0;
    width: 40%;
    height: 100%;
    z-index: -1;
    pointer-events: auto;
  }
}
@media (min-width: 1024px) {
  .v8mf6o2::before {
    width: calc(50% - var(--grid-column-width) - var(--grid-gap) - calc(var(--grid-gap) / 2));
  }
}
@media (max-width: 1023px) {
  .v8mf6o2 > *:nth-child(1) {
    grid-column: 1 / span 4;
  }
  .v8mf6o2 > *:nth-child(2) {
    grid-column: 6 / span 3;
  }
  .v8mf6o2 > *:nth-child(3) {
    grid-column: 9 / span 4;
  }
}
@media (max-width: 767px) {
  .v8mf6o2 {
    padding-top: 0;
  }
  .v8mf6o2 > *:nth-child(1) {
    grid-column: span 4;
  }
  .v8mf6o2 > *:nth-child(2) {
    grid-column: span 4;
  }
  .v8mf6o2 > *:nth-child(3) {
    grid-column: span 4;
  }
  .v8mf6o5 {
    background: var(--_18bwoz27);
    padding: 20px 15px;
    margin-inline: calc(-1 * var(--grid-gap));
  }
}