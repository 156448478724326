._165qga30 {
  padding-bottom: 80px;
  margin-top: 80px;
  border-bottom: 1px solid var(--_18bwoz26);
}
._165qga31 {
  grid-column: 1 / span 8;
}
._165qga32 {
  grid-column: 10 / span 3;
}
@media (max-width: 767px) {
  ._165qga30 {
    padding-bottom: 40px;
    margin-top: 40px;
  }
  ._165qga31 {
    grid-column: span 4;
  }
  ._165qga32 {
    grid-column: span 4;
  }
}