.dxivd0 {
  display: block;
}
.dxivd1 {
  grid-column: 1 / span 12;
}
.dxivd2 {
  grid-column: 3 / span 8;
}
.dxivd3 {
  grid-column: 4 / span 6;
}
@media (max-width: 767px) {
  .dxivd0 {
    grid-column: 1 / span 4;
  }
}