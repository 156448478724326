._17niesl0 {
  margin-bottom: 100px;
  margin-top: 8px;
  position: relative;
  z-index: 0;
}
._17niesl1 {
  align-items: start;
  grid-column: span 12;
  grid-row-gap: unset;
}
._17niesl2 {
  background-color: var(--_18bwoz21);
  border-bottom: 1px solid var(--_18bwoz27);
  grid-column: span 12;
  position: sticky;
  margin-bottom: 32px;
  top: 48px;
  z-index: 1;
}
._17niesl3 {
  display: flex;
  justify-content: center;
  grid-column: 3 / span 8;
  padding: 0;
  margin: 0;
}
._17niesl4 {
  display: flex;
  align-items: center;
  height: 60px;
  list-style: none;
  margin: 0 1.875rem;
  padding: 0;
  border-bottom: 3px solid var(--_18bwoz21);
  color: var(--_18bwoz26);
  text-align: center;
}
._17niesl4:first-child {
  margin-left: 0;
}
._17niesl5 {
  border-bottom-color: var(--_18bwoz22);
  color: var(--_18bwoz20);
}
._17niesl6 {
  margin: 0;
}
._17niesl7 {
  color: inherit;
  display: inline-block;
  padding: 16px 0;
  text-decoration: none;
}
._17niesl7 > * {
  line-height: 1.25em;
}
._17niesl8 {
  align-items: start;
  background-color: var(--_18bwoz24);
  color: var(--_18bwoz21);
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 3;
  height: 65vh;
  padding: 40px;
  position: sticky;
  top: calc(48px + 60px + 32px);
}
._17niesl9 {
  grid-column: 4 / span 9;
}
._17niesla {
  --offset-top: calc(48px + 60px + 32px);
  position: relative;
  padding-top: var(--offset-top);
  margin-top: calc(var(--offset-top) * -1);
  margin-bottom: 100px;
}
._17niesla:last-child {
  margin-bottom: 0;
}
._17nieslb {
  --grid-column-count: 9;
  grid-row-gap: unset;
}
._17nieslc {
  grid-column: span 9;
  padding: 0 var(--grid-gap);
  margin-bottom: 20px;
}
._17niesld {
  grid-column: span 8;
  padding: 0 var(--grid-gap);
}
._17niesld.u3zewj0 ul {
  margin: 20px 0 0;
}
._17niesld.u3zewj0 li {
  padding-bottom: 5px;
}
._17niesle {
  column-gap: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-column: span 8;
  margin-top: 30px;
  padding: 0 var(--grid-gap);
}
._17nieslf {
  display: flex;
  flex-direction: column;
  margin: 0 0 16px;
  width: 100%;
}
._17nieslg {
  display: block;
  margin: 0 0 20px;
}
._17nieslg ._1csod971 {
  display: block;
  height: 30px;
  max-width: unset;
}
._17nieslh {
  display: block;
  margin-bottom: 35px;
}
._17nieslh ._1csod971 {
  display: block;
  min-height: 200px;
  object-fit: cover;
}
._17niesli {
  margin-bottom: 10px;
}
._17nieslj {
  flex: 1;
}
._17nieslk {
  font-weight: 500;
  margin-top: 20px;
  width: 100%;
}
._17nieslm, ._17nieslm ._1csod970, ._17nieslm ._1csod971 {
  display: inline-block;
}
@media (max-width: 1439px) {
  ._17niesl4 {
    height: unset;
    margin: 0 2vw;
  }
}
@media (max-width: 1023px) {
  ._17niesl4 {
    margin: 0 1.25rem;
  }
  ._17niesl7 {
    padding: 8px 0;
  }
  ._17niesl8 {
    display: none;
  }
  ._17niesl9 {
    grid-column: 1 / -1;
  }
}
@media (max-width: 767px) {
  ._17niesl1 {
    grid-column: span 4;
  }
  ._17niesl2 {
    grid-column: span 4;
    top: 42px;
  }
  ._17niesl3 {
    overflow-x: auto;
    justify-content: flex-start;
  }
  ._17niesl4 {
    justify-content: center;
    margin: 0.5rem 1.25rem 0.5rem 0;
    min-width: 8.75rem;
  }
  ._17niesl7 > * {
    font-size: 0.875rem;
  }
  ._17nieslc {
    padding: 0;
  }
  ._17niesld {
    grid-column: 1 / -1;
    padding: 0;
  }
  ._17niesle {
    grid-column: 1 / -1;
    padding: 0 16px;
  }
}