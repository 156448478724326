._1ts6n1n1 {
  padding-inline: 1vw;
}
._1ts6n1n1 table {
  border-collapse: collapse;
}
._1ts6n1n1 thead th {
  border-bottom: 1px solid var(--_18bwoz20);
  text-transform: uppercase;
}
._1ts6n1n1 tbody td {
  border-bottom: 1px solid var(--_18bwoz26);
}
._1ts6n1n1 th {
  padding-block: 0.5rem;
  padding-right: 1rem;
  text-align: left;
}
._1ts6n1n1 td {
  padding-block: 0.5rem;
  padding-right: 1rem;
  vertical-align: top;
}