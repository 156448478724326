._1uic8rn0 {
  margin-top: 80px;
  margin-bottom: 80px;
}
._1uic8rn1 {
  margin-block: 0;
  text-align: center;
}
._1uic8rn2 {
  text-align: center;
  margin: 40px 0;
}
@media (max-width: 767px) {
  ._1uic8rn0 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}