.u3zewj0 .accent_color_blue_gray {
  color: var(--_18bwoz22);
}
.u3zewj0 a.button {
  background-color: var(--_18bwoz22);
  border: 2px solid var(--_18bwoz22);
  color: var(--_18bwoz21);
  cursor: pointer;
  display: inline-block;
  font-size: var(--_1ttbmbvm);
  line-height: var(--_1ttbmbv1b);
  margin: 0.5rem 0;
  padding: 0.75rem 1.75rem;
  text-align: center;
  text-decoration: none;
}
.u3zewj0 a.button--secondary {
  background-color: var(--_18bwoz21);
  border: 2px solid var(--_18bwoz22);
  color: var(--_18bwoz22);
  font-size: var(--_1ttbmbvm);
  line-height: var(--_1ttbmbv1b);
}