._17ts7bg1 {
  display: block;
}
._17ts7bg2 {
  grid-column: 1 / span 12;
}
._17ts7bg3 {
  grid-column: 3 / span 8;
}
._17ts7bg4 {
  grid-column: 4 / span 6;
}
._17ts7bg5 {
  width: 100%;
  height: auto;
  object-fit: cover;
}
@media (max-width: 767px) {
  ._17ts7bg1 {
    grid-column: 1 / span 4;
  }
}