.dk9q4y0 {
  padding: 3rem 0;
}
.dk9q4y2 {
  margin-bottom: 40px;
}
.dk9q4y2 > * {
  grid-column: 3 / span 8;
}
.dk9q4y3 {
  margin-bottom: 124px;
}
.dk9q4y4 {
  --left-content-grid-column: 1 / span 5;
  --right-content-grid-column: 7 / span 6;
}
.dk9q4y4[data-reach-tabs][data-orientation=vertical] {
  display: grid;
}
.dk9q4y5 {
  visibility: hidden;
}
.dk9q4y6 {
  visibility: visible;
}
.dk9q4y7 {
  grid-column: var(--right-content-grid-column);
  width: 100%;
}
.dk9q4y8 {
  background-color: transparent;
  grid-column: var(--left-content-grid-column);
  width: 100%;
}
.dk9q4y9 {
  align-items: center;
  border-bottom: 1px solid var(--_18bwoz26);
  display: flex;
  justify-content: space-between;
  padding: 30px 0 24px;
  text-align: left;
  gap: var(--grid-gap);
}
.dk9q4y9[aria-selected=true] {
  font-weight: 900;
}
.dk9q4y9[aria-selected=false] svg {
  opacity: 0.8;
}
.dk9q4ya {
  margin-bottom: 0;
}
button[aria-selected=true] .dk9q4ya {
  font-family: Nexa-Bold;
}
.dk9q4yb {
  text-align: center;
}
.dk9q4yc {
  grid-column: 1 / span 5;
}
.dk9q4ye {
  padding: 0;
}
.dk9q4yf {
  padding: 0;
}
.dk9q4yg {
  grid-column: 7 / span 6;
}
.dk9q4yg > *:not(:last-child) {
  margin-bottom: 1rem;
}
.dk9q4yf .wjlq0t3 {
  margin-top: unset;
}
.dk9q4yh {
  min-width: 15px;
  width: 15px;
}
.dk9q4yj {
  fill: var(--_18bwoz21);
}
.dk9q4yl {
  fill: var(--_18bwoz21);
}
.dk9q4ym {
  fill: var(--_18bwoz21);
}
.dk9q4yn a {
  color: inherit;
}
.dk9q4yo {
  column-gap: 20px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid currentColor;
}
.dk9q4yp {
  display: flex;
  flex-direction: column;
  margin: 0 0 16px;
  width: 100%;
}
.dk9q4yq {
  display: block;
  margin: 0 0 20px;
}
.dk9q4yq ._1csod971 {
  display: block;
  height: 24px;
  max-width: unset;
}
.dk9q4yr {
  font-weight: 500;
}
@media (max-width: 1023px) {
  .dk9q4y2 > * {
    grid-column: 2 / span 10;
  }
  .dk9q4y4 {
    --left-content-grid-column: 1 / span 6;
    --right-content-grid-column: 7 / span 6;
  }
}
@media (max-width: 767px) {
  .dk9q4y2 > * {
    grid-column: 1 / -1;
  }
  .dk9q4y3 {
    margin-bottom: 30px;
  }
  .dk9q4y7 {
    grid-column: 1 / -1;
  }
  .dk9q4y8 {
    grid-column: 1 / -1;
    width: auto;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .dk9q4y9 {
    align-items: start;
    border-left: 0;
    padding: 0.5rem 1rem 0.5rem 0;
    min-height: 4rem;
    width: 33.3333%;
    flex-shrink: 0;
  }
  .dk9q4ya {
    font-size: var(--_1ttbmbvz);
    line-height: var(--_1ttbmbv1p);
  }
  .dk9q4yc {
    grid-column: 1 / span 4;
    text-align: center;
  }
  .dk9q4yd {
    font-family: var(--_1ttbmbv1);
    font-size: var(--_1ttbmbvw);
    line-height: var(--_1ttbmbv1l);
    letter-spacing: var(--_1ttbmbv1s);
  }
  .dk9q4yg {
    grid-column: 1 / span 4;
    text-align: center;
  }
  .dk9q4yh {
    display: none;
  }
}
@media (max-width: 479px) {

}
@media (min-width: 768px) {
  .dk9q4y9:first-child {
    padding-top: 0;
  }
}
@media (max-width: 1023px) and (min-width: 768px) {
  .dk9q4yg {
    margin-left: var(--grid-gap);
  }
  .dk9q4yn {
    margin-left: var(--grid-gap);
  }
  .dk9q4yo {
    margin-left: var(--grid-gap);
  }
}