.sor4vs0 {
  background: rgba(12, 30, 48, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--window-inner-height);
  z-index: 100;
  transition: opacity 0.5s, transform 0.5s;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.sor4vs1 {
  height: 100%;
}
.sor4vs2 {
  height: calc(var(--window-inner-height) - 1px);
  overflow: hidden;
  box-sizing: border-box;
}
.sor4vs2 body {
  height: calc(var(--window-inner-height) - 1px);
  overflow: hidden;
  box-sizing: border-box;
}
.sor4vs3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-column: 4 / span 6;
  padding-block: 4rem;
  color: var(--_18bwoz21);
  text-align: center;
}
.sor4vs4:focus-visible {
  outline: 0;
  filter: 
            drop-shadow(1px 1px 1px var(--_18bwoz21))
            drop-shadow(-1px -1px 1px var(--_18bwoz21))
            drop-shadow(1px -1px 1px var(--_18bwoz21))
            drop-shadow(-1px 1px 1px var(--_18bwoz21))
        ;
}
.sor4vs5 {
  margin-bottom: 5rem;
}
.sor4vs6 {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
}
.sor4vs7 {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--_18bwoz21);
}
@media (max-width: 1023px) {
  .sor4vs3 {
    grid-column: 3 / span 8;
  }
}
@media (max-width: 767px) {
  .sor4vs3 {
    grid-column: span 4;
  }
}