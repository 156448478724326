.nr1qt61 {
  background-color: var(--_18bwoz23);
}
.nr1qt62 {
  margin-bottom: 10rem;
}
.nr1qt63 {
  grid-column: 4 / span 6;
  text-align: center;
}
@media (max-width: 1023px) {
  .nr1qt63 {
    grid-column: 3 / span 8;
  }
}
@media (max-width: 767px) {
  .nr1qt63 {
    grid-column: 1 / -1;
  }
}