.a33di40 {
  border-bottom: 1px solid var(--_18bwoz27);
  padding: 40px 0;
}
.a33di41 {
  color: var(--_18bwoz22);
}
.a33di42 {
  margin-bottom: 0;
}
.a33di43 {
  height: unset;
  line-height: 32px;
  padding-bottom: 0;
  padding-top: 0;
  margin-top: 10px;
}