._5v9vrg1 {
  --grid-column-count: 24;
  --grid-gap: 10px;
  align-items: center;
  padding: 80px 0;
}
._5v9vrg2 {
  grid-column: 1 / span 11;
}
._5v9vrg2 ._1csod971 {
  display: block;
}
._5v9vrg3 {
  grid-column: 13 / span 11;
}
._5v9vrg4 {
  margin-top: 20px;
}
._5v9vrg5 {
  color: var(--_18bwoz22);
  margin-bottom: 13px;
}
._5v9vrg6 {
  margin: 0;
}
@media (max-width: 1023px) {
  ._5v9vrg1 {
    --grid-gap: 7.5px;
  }
}
@media (max-width: 767px) {
  ._5v9vrg1 {
    --grid-column-count: 4;
    padding: 40px 0;
  }
  ._5v9vrg2 {
    grid-column: span 4;
  }
  ._5v9vrg3 {
    grid-column: span 4;
    margin-top: 20px;
  }
}