.n0uhei0 {
  display: block;
  padding: 0.75rem 0.5rem;
  margin: 0 0.75rem;
  font-size: var(--_1ttbmbvn);
  line-height: var(--_1ttbmbv1c);
  text-decoration: none;
}
.n0uhei1 {
  width: calc(100% - 0.75rem - 0.75rem);
  text-align: left;
}
.n0uhei3 {
  width: 14px;
  height: 12px;
  float: right;
  margin-top: 6px;
}
.n0uhei3:not(.n0uhei2) {
  display: none;
}
.n0uhei4 {
  transform: rotate(180deg);
}
.yls3ux5:not(.yls3ux6) li:focus-within .n0uhei0,
     .yls3ux5:not(.yls3ux6) li:hover .n0uhei0 {
  box-shadow: inset 0 -3px 0 0 var(--_18bwoz24);
}