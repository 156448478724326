.p2plh00 {
  margin: 100px calc(max(calc(calc(100vw - 1360px) / 2), 40px) / 3);
}
.p2plh01 {
  --left-content-grid-column: 1 / span 4;
  --right-content-grid-column: 6 / span 5;
  background-color: var(--_18bwoz27);
  padding-bottom: 106px;
  margin-left: calc(max(calc(calc(100vw - 1360px) / 2), 60px) - calc(max(calc(calc(100vw - 1360px) / 2), 60px) / 3));
  margin-right: calc(max(calc(calc(100vw - 1360px) / 2), 60px) - calc(max(calc(calc(100vw - 1360px) / 2), 60px) / 3));
  max-width: 1360px;
}
.p2plh02 {
  padding-top: 90px;
  margin-bottom: 60px;
  text-align: center;
}
.p2plh04 {
  margin-bottom: 0;
}
.p2plh06 {
  grid-column: var(--left-content-grid-column);
}
.p2plh07 {
  height: auto;
  max-width: 100%;
}
.p2plh08 {
  height: auto;
  max-width: 100%;
}
.p2plh09 {
  position: relative;
  margin-bottom: 40px;
}
.p2plh09  * {
  display: inline;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.p2plh09 ._1ttbmbv2z, .p2plh09 ._1ttbmbv30 {
  display: inline;
}
.p2plh0a {
  color: var(--_18bwoz22);
  font-size: 40px;
}
.p2plh0a:first-child {
  left: -18px;
  position: absolute;
  top: -3px;
}
.p2plh0c {
  grid-column: var(--right-content-grid-column);
}
@media (max-width: 1023px) {
  .p2plh00 {
    margin-left: calc(max(calc(calc(100vw - 1360px) / 2), 40px) / 3);
    margin-right: calc(max(calc(calc(100vw - 1360px) / 2), 40px) / 3);
  }
  .p2plh01 {
    margin-left: calc(max(calc(calc(100vw - 1360px) / 2), 40px) - calc(max(calc(calc(100vw - 1360px) / 2), 40px) / 3));
    margin-right: calc(max(calc(calc(100vw - 1360px) / 2), 40px) - calc(max(calc(calc(100vw - 1360px) / 2), 40px) / 3));
  }
}
@media (max-width: 767px) {
  .p2plh00 {
    margin: 50px calc(max(calc(calc(100vw - 1360px) / 2), 15px) / 3);
  }
  .p2plh01 {
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0 calc(max(calc(calc(100vw - 1360px) / 2), 15px) - calc(max(calc(calc(100vw - 1360px) / 2), 15px) / 3)) 50px;
  }
  .p2plh05 {
    flex-direction: column;
  }
  .p2plh06 {
    width: 100%;
  }
  .p2plh0a:first-child {
    position: static;
  }
  .p2plh0c {
    margin-top: 30px;
    padding: 0;
    width: 100%;
  }
}