.zcq6pu0 {
  padding: 8px 0 0 0;
  position: sticky;
  z-index: 1;
  top: 48px;
  background-color: var(--_18bwoz21);
  border-bottom: 1px solid var(--_18bwoz27);
}
.zcq6pu2 {
  grid-column: span 12;
}
@media (max-width: 767px) {
  .zcq6pu0 {
    top: 42px;
  }
  .zcq6pu2 {
    grid-column: span 4;
  }
}