:root {
  --_1ttbmbv0: Nexa-Light;
  --_1ttbmbv1: Nexa-Light;
  --_1ttbmbv2: Nexa-Light;
  --_1ttbmbv3: Nexa-Light;
  --_1ttbmbv4: Nexa-Bold;
  --_1ttbmbv5: Nexa-Bold;
  --_1ttbmbv6: NunitoSans-Regular;
  --_1ttbmbv7: NunitoSans-Regular;
  --_1ttbmbv8: NunitoSans-Regular;
  --_1ttbmbv9: NunitoSans-Light;
  --_1ttbmbva: Nexa-Bold;
  --_1ttbmbvb: Nexa-Bold;
  --_1ttbmbvc: 56px;
  --_1ttbmbvd: 50px;
  --_1ttbmbve: 40px;
  --_1ttbmbvf: 24px;
  --_1ttbmbvg: 20px;
  --_1ttbmbvh: 16px;
  --_1ttbmbvi: 18px;
  --_1ttbmbvj: 16px;
  --_1ttbmbvk: 12px;
  --_1ttbmbvl: 30px;
  --_1ttbmbvm: 16px;
  --_1ttbmbvn: 16px;
  --_1ttbmbvo: 12px;
  --_1ttbmbvp: 50px;
  --_1ttbmbvq: 40px;
  --_1ttbmbvr: 32px;
  --_1ttbmbvs: 19px;
  --_1ttbmbvt: 16px;
  --_1ttbmbvu: 28px;
  --_1ttbmbvv: 40px;
  --_1ttbmbvw: 30px;
  --_1ttbmbvx: 24px;
  --_1ttbmbvy: 18px;
  --_1ttbmbvz: 16px;
  --_1ttbmbv10: 24px;
  --_1ttbmbv11: 64px;
  --_1ttbmbv12: 60px;
  --_1ttbmbv13: 50px;
  --_1ttbmbv14: 32px;
  --_1ttbmbv15: 28px;
  --_1ttbmbv16: 24px;
  --_1ttbmbv17: 26px;
  --_1ttbmbv18: 24px;
  --_1ttbmbv19: 16px;
  --_1ttbmbv1a: 38px;
  --_1ttbmbv1b: 24px;
  --_1ttbmbv1c: 24px;
  --_1ttbmbv1d: 18px;
  --_1ttbmbv1e: 60px;
  --_1ttbmbv1f: 50px;
  --_1ttbmbv1g: 40px;
  --_1ttbmbv1h: 28px;
  --_1ttbmbv1i: 24px;
  --_1ttbmbv1j: 36px;
  --_1ttbmbv1k: 50px;
  --_1ttbmbv1l: 40px;
  --_1ttbmbv1m: 30px;
  --_1ttbmbv1n: 30px;
  --_1ttbmbv1o: 26px;
  --_1ttbmbv1p: 24px;
  --_1ttbmbv1q: 32px;
  --_1ttbmbv1r: 0.1em;
  --_1ttbmbv1s: 0.1em;
  --_1ttbmbv1t: 0.1em;
  --_1ttbmbv1u: 0.1em;
  --_1ttbmbv1v: 0.15em;
  --_1ttbmbv1w: 0.15em;
  --_1ttbmbv1x: 0.02em;
  --_1ttbmbv1y: 0.02em;
  --_1ttbmbv1z: 0.02em;
  --_1ttbmbv20: 0.02em;
  --_1ttbmbv21: 0.15em;
  --_1ttbmbv22: 0.15em;
  --_1ttbmbv23: 300;
  --_1ttbmbv24: 300;
  --_1ttbmbv25: 300;
  --_1ttbmbv26: 300;
  --_1ttbmbv27: 700;
  --_1ttbmbv28: 700;
  --_1ttbmbv29: 400;
  --_1ttbmbv2a: 400;
  --_1ttbmbv2b: 400;
  --_1ttbmbv2c: 300;
  --_1ttbmbv2d: 700;
  --_1ttbmbv2e: 700;
  --_1ttbmbv2f: uppercase;
  --_1ttbmbv2g: uppercase;
  --_1ttbmbv2h: uppercase;
  --_1ttbmbv2i: uppercase;
  --_1ttbmbv2j: uppercase;
  --_1ttbmbv2k: uppercase;
}
._1ttbmbv2l {
  font-family: var(--_1ttbmbv0);
  font-size: var(--_1ttbmbvc);
  font-weight: var(--_1ttbmbv23);
  line-height: var(--_1ttbmbv11);
  letter-spacing: var(--_1ttbmbv1r);
  text-transform: var(--_1ttbmbv2f);
  margin: 0 0 12px;
}
._1ttbmbv2m {
  font-family: var(--_1ttbmbv1);
  font-size: var(--_1ttbmbvd);
  font-weight: var(--_1ttbmbv24);
  line-height: var(--_1ttbmbv12);
  letter-spacing: var(--_1ttbmbv1s);
  text-transform: var(--_1ttbmbv2g);
  margin: 0 0 10px;
}
._1ttbmbv2n {
  font-family: var(--_1ttbmbv2);
  font-size: var(--_1ttbmbve);
  font-weight: var(--_1ttbmbv25);
  line-height: var(--_1ttbmbv13);
  letter-spacing: var(--_1ttbmbv1t);
  text-transform: var(--_1ttbmbv2h);
  margin: 0 0 8px;
}
._1ttbmbv2o {
  font-family: var(--_1ttbmbv3);
  font-size: var(--_1ttbmbvf);
  font-weight: var(--_1ttbmbv26);
  line-height: var(--_1ttbmbv14);
  letter-spacing: var(--_1ttbmbv1u);
  text-transform: var(--_1ttbmbv2i);
  margin: 0 0 8px;
}
._1ttbmbv2p {
  font-family: var(--_1ttbmbv4);
  font-size: var(--_1ttbmbvg);
  font-weight: var(--_1ttbmbv27);
  line-height: var(--_1ttbmbv15);
  letter-spacing: var(--_1ttbmbv1v);
  text-transform: var(--_1ttbmbv2j);
  margin: 0 0 8px;
}
._1ttbmbv2q {
  font-family: var(--_1ttbmbv5);
  font-size: var(--_1ttbmbvh);
  font-weight: var(--_1ttbmbv28);
  line-height: var(--_1ttbmbv16);
  letter-spacing: var(--_1ttbmbv1w);
  text-transform: var(--_1ttbmbv2k);
  margin: 0;
}
._1ttbmbv2r {
  font-family: var(--_1ttbmbv6);
  font-size: var(--_1ttbmbvi);
  font-weight: var(--_1ttbmbv29);
  line-height: var(--_1ttbmbv17);
  letter-spacing: var(--_1ttbmbv1x);
  margin: 0;
}
._1ttbmbv2s {
  font-family: var(--_1ttbmbv7);
  font-size: var(--_1ttbmbvj);
  font-weight: var(--_1ttbmbv2a);
  line-height: var(--_1ttbmbv18);
  letter-spacing: var(--_1ttbmbv1y);
  margin: 0;
}
._1ttbmbv2t {
  font-family: var(--_1ttbmbv8);
  font-size: var(--_1ttbmbvk);
  font-weight: var(--_1ttbmbv2b);
  line-height: var(--_1ttbmbvk);
  letter-spacing: var(--_1ttbmbv1z);
  margin: 0;
}
._1ttbmbv2u {
  font-family: var(--_1ttbmbv9);
  font-size: var(--_1ttbmbvl);
  font-weight: var(--_1ttbmbv2c);
  line-height: var(--_1ttbmbv1a);
  letter-spacing: var(--_1ttbmbv20);
}
._1ttbmbv2v {
  font-family: var(--_1ttbmbva);
  font-size: var(--_1ttbmbvm);
  font-weight: var(--_1ttbmbv2d);
  line-height: var(--_1ttbmbv1b);
  letter-spacing: var(--_1ttbmbv21);
}
._1ttbmbv2w {
  font-family: var(--_1ttbmbva);
  font-size: var(--_1ttbmbvm);
  font-weight: var(--_1ttbmbv2d);
  line-height: var(--_1ttbmbv1b);
  letter-spacing: var(--_1ttbmbv21);
}
@media (max-width: 1023px) {
  ._1ttbmbv2l {
    font-size: var(--_1ttbmbvp);
    line-height: var(--_1ttbmbv1e);
  }
  ._1ttbmbv2m {
    font-size: var(--_1ttbmbvq);
    line-height: var(--_1ttbmbv1f);
  }
  ._1ttbmbv2n {
    font-size: var(--_1ttbmbvr);
    line-height: var(--_1ttbmbv1g);
  }
  ._1ttbmbv2p {
    font-size: var(--_1ttbmbvs);
    line-height: var(--_1ttbmbv1h);
  }
  ._1ttbmbv2q {
    font-size: var(--_1ttbmbvt);
    line-height: var(--_1ttbmbv1i);
  }
  ._1ttbmbv2u {
    font-size: var(--_1ttbmbvu);
    line-height: var(--_1ttbmbv1j);
  }
}
@media (max-width: 767px) {
  ._1ttbmbv2l {
    font-size: var(--_1ttbmbvv);
    line-height: var(--_1ttbmbv1k);
  }
  ._1ttbmbv2m {
    font-size: var(--_1ttbmbvw);
    line-height: var(--_1ttbmbv1l);
  }
  ._1ttbmbv2n {
    font-size: var(--_1ttbmbvx);
    line-height: var(--_1ttbmbv1m);
  }
  ._1ttbmbv2p {
    font-size: var(--_1ttbmbvy);
    line-height: var(--_1ttbmbv1o);
  }
  ._1ttbmbv2q {
    font-size: var(--_1ttbmbvz);
    line-height: var(--_1ttbmbv1p);
  }
  ._1ttbmbv2u {
    font-size: var(--_1ttbmbv10);
    line-height: var(--_1ttbmbv1q);
  }
}