._1p7onmp0 {
  width: 100%;
}
._1p7onmp2 {
  padding-bottom: 9px;
  text-transform: uppercase;
}
._1p7onmp4::after {
  content: "*";
  font-size: 0.8rem;
}
._1p7onmp5 {
  color: var(--_18bwoz2a);
  padding-top: 5px;
}
._1p7onmp0 label {
  display: block;
}

    ._1p7onmp0 input,
    ._1p7onmp0 textarea
     {
  background-color: var(--_18bwoz24);
  border: 0;
  border-bottom: 2px solid var(--_18bwoz27);
  color: var(--_18bwoz21);
  font-family: var(--_1ttbmbv7);
  font-size: var(--_1ttbmbvj);
  line-height: var(--_1ttbmbv18);
  letter-spacing: var(--_1ttbmbv1y);
  padding: 15px 20px;
  width: 100%;
}

    ._1p7onmp0._1p7onmp1 input,
    ._1p7onmp0._1p7onmp1 textarea
     {
  border-bottom: 2px solid var(--_18bwoz29);
}
._1p7onmp0 textarea {
  display: block;
  min-height: 10rem;
}