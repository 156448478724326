._1qbphz80 {
  background-position: center;
  background-size: cover;
  color: var(--_18bwoz21);
}
._1qbphz81 {
  --left-content-grid-column: 1 / span 4;
  --right-content-grid-column: 6 / span 5;
  padding: 100px 0 60px;
}
._1qbphz81 > div:last-child {
  display: grid;
}
._1qbphz82 {
  grid-column: var(--left-content-grid-column);
}
._1qbphz83 {
  align-items: end;
  padding-bottom: 54px;
}
._1qbphz84 {
  display: flex;
  grid-column: var(--right-content-grid-column);
  justify-content: end;
}
._1qbphz85 {
  margin-bottom: 27px;
}
._1qbphz87 {
  background-color: transparent;
  grid-column: var(--left-content-grid-column);
}
._1qbphz88 {
  margin-bottom: 15px;
}
._1qbphz89.u3zewj0 a {
  color: inherit;
}
._1qbphz89.u3zewj0 p {
  min-height: 1rem;
}
._1qbphz8a {
  margin: 76px 0 8px;
}
._1qbphz8b {
  padding-top: 20px;
  grid-column: var(--right-content-grid-column);
}
._1qbphz8c {
  visibility: hidden;
}
._1qbphz8d {
  visibility: visible;
}
._1qbphz8e {
  border-bottom: none;
  border-left: 2px solid rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 45px 0 45px 45px;
  min-height: 9rem;
  text-align: left;
}
._1qbphz8f {
  opacity: 0.5;
  margin: 0;
}
._1qbphz8f:hover {
  opacity: 1;
  transition: opacity 1s linear;
}
._1qbphz8g {
  opacity: 0;
  visibility: hidden;
}
._1qbphz8h {
  padding-bottom: 0;
}
._1qbphz8e[data-selected] {
  pointer-events: none;
  position: relative;
}
._1qbphz8e[data-selected]::before {
  content: "";
  background-color: var(--_18bwoz21);
  display: block;
  height: 100%;
  left: -3px;
  position: absolute;
  top: 0;
  width: 4px;
}
._1qbphz8e[data-selected] ._1qbphz8g {
  pointer-events: auto;
  transition: visibility 0.5s linear, opacity 0.5s linear;
  opacity: 1;
  visibility: visible;
}
._1qbphz8e[data-selected] ._1qbphz8f {
  opacity: 1;
  transition: opacity 1s linear;
}
._1qbphz8i {
  display: flex;
  align-items: center;
}
._1qbphz8j {
  height: auto;
  padding-right: 20px;
  max-width: 125px;
}
._1qbphz8j:last-child {
  padding-right: 0;
}
@media (max-width: 1023px) {
  ._1qbphz8e {
    padding: 25px 0 25px 25px;
  }
}
@media (max-width: 767px) {
  ._1qbphz81 > div:last-child {
    display: flex;
    flex-direction: column;
  }
  ._1qbphz82 {
    text-align: left;
  }
  ._1qbphz83 {
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  ._1qbphz84 {
    justify-content: start;
  }
  ._1qbphz87 {
    flex-direction: column;
  }
  ._1qbphz8b {
    padding-left: 0;
    padding-top: 2rem;
  }
  ._1qbphz8e {
    align-items: start;
    border-left: 0;
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    padding: 0;
    margin-bottom: 8px;
    min-height: unset;
    width: 100%;
  }
  ._1qbphz8g {
    display: none;
  }
  ._1qbphz8e[data-selected]::before {
    bottom: -3px;
    height: 4px;
    left: 0;
    top: unset;
    width: 100%;
  }
  ._1qbphz8e[data-selected] ._1qbphz8g {
    display: block;
  }
}