:root {
  --_18bwoz20: #000;
  --_18bwoz21: #fff;
  --_18bwoz22: #55749a;
  --_18bwoz23: #134360;
  --_18bwoz24: #0c1e30;
  --_18bwoz25: #143952;
  --_18bwoz26: #858687;
  --_18bwoz27: #e5e6e7;
  --_18bwoz28: #F5F5F5;
  --_18bwoz29: #ff0f00;
  --_18bwoz2a: #ff9e98;
  --_18bwoz2b: #fbe4a8;
  --_18bwoz2c: #0e76a8;
  --_18bwoz2d: #1877f2;
  --_18bwoz2e: #ff0000;
  --_18bwoz2f: #1da1f2;
}
:where(._18bwoz2g) {
  margin: 0;
  padding: 0;
  list-style: none;
}
:where(._18bwoz2g) > :where(li, dt, dd), :where(._18bwoz2g) > :where(div) dd {
  margin: 0;
  padding: 0;
}
sup {
  display: inline-block;
  font-size: 0.6em;
  letter-spacing: -3px;
  transform: translateX(-6px);
}
._18bwoz2h {
  align-items: center;
  background: var(--_18bwoz21);
  box-shadow: 0px 0px 2px var(--_18bwoz26);
  border: none;
  border-radius: 50%;
  display: flex;
  height: 45px;
  justify-content: center;
  left: 0;
  margin: 0;
  padding: 12px;
  position: absolute;
  pointer-events: auto;
  top: 50%;
  transform: translateY(-50%);
  width: 45px;
}
._18bwoz2h:last-child {
  left: unset;
  right: 0;
}
._18bwoz2h:disabled {
  pointer-events: none;
  opacity: 0;
}
._18bwoz2i {
  width: 100%;
}