._1ykw5bl0 {
  position: relative;
}
._1ykw5bl1 > div {
  position: relative;
}
._1ykw5bl2 {
  top: min(50%, 180px);
}
._1ykw5bl4 {
  transform: rotate(180deg);
}
@media (max-width: 1439px) {
  ._1ykw5bl2 {
    top: min(50%, 160px);
  }
}
@media (max-width: 1023px) {
  ._1ykw5bl2 {
    top: min(50%, 210px);
  }
}
@media (max-width: 767px) {
  ._1ykw5bl2 {
    top: min(50%, 160px);
  }
}