._13zlznl1 {
  --full-width-grid-column: 1 / -1;
  --header-grid-column: 3 / span 8;
}
._13zlznl2 {
  grid-column: var(--header-grid-column);
  padding-top: 100px;
  text-align: center;
}
._13zlznl3 {
  margin-bottom: 12px;
}
._13zlznl5 {
  grid-column: var(--full-width-grid-column);
}
._13zlznl6 {
  margin-bottom: 40px;
  margin-top: 100px;
  text-align: center;
}
._13zlznl8 {
  margin-bottom: 54px;
}
._13zlznl9 {
  --tile-columns: 5;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-column-gap: var(--grid-gap);
  grid-row-gap: 2.5rem;
  border-bottom: 1px solid var(--_18bwoz26);
  padding-bottom: 6.25rem;
}
._13zlznlc {
  width: calc((100% / var(--tile-columns)) - var(--grid-gap) + (var(--grid-gap) / var(--tile-columns)));
  flex-shrink: 0;
}
._13zlznld {
  margin-bottom: 0;
}
._13zlznlf {
  aspect-ratio: 439 / 548;
  display: block;
  position: relative;
  margin-bottom: 25px;
  width: 100%;
}
._13zlznlf ._1csod971 {
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
._13zlznlg {
  height: unset;
  line-height: 32px;
  padding-bottom: 0;
  padding-top: 0;
  margin-top: 10px;
}
._13zlznlh {
  grid-column: var(--full-width-grid-column);
}
._13zlznli {
  border-bottom: 1px solid var(--_18bwoz26);
  padding: 40px 0;
}
._13zlznli:first-child {
  padding-top: 0;
}
._13zlznli:last-child {
  border-bottom: none;
}
._13zlznlj {
  border-bottom: 1px solid var(--_18bwoz26);
  padding: 20px 0;
}
._13zlznlj:last-child {
  border-bottom: none;
}
._13zlznlk {
  background-color: transparent;
  border: none;
  color: inherit;
  padding: 0;
  position: relative;
  margin: 0;
  text-align: left;
  width: 100%;
}
._13zlznlq {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 30px);
}
button[aria-expanded=true] ._13zlznlq {
  display: none;
}
._13zlznlr {
  position: absolute;
  right: 0;
  top: 0;
  visibility: hidden;
  width: 20px;
}
._13zlznls {
  height: 20px;
}
button[aria-expanded=false] ._13zlznls {
  visibility: visible;
}
._13zlznlt {
  height: 2px;
  top: 8px;
}
button[aria-expanded=true] ._13zlznlt {
  visibility: visible;
}
._13zlznln ._1mn7yhj8 {
  margin-top: 19px;
}
._13zlznln ._1mn7yhj5 {
  fill: var(--_18bwoz2c);
}
@media (max-width: 1023px) {
  ._13zlznl9 {
    --tile-columns: 4;
  }
}
@media (max-width: 767px) {
  ._13zlznl1 {
    --header-grid-column: 1 / -1;
  }
  ._13zlznl6 {
    margin: 3rem 0;
  }
  ._13zlznl8 {
    margin-bottom: 1.25rem;
  }
  ._13zlznl9 {
    --tile-columns: 2;
    padding-bottom: 3rem;
  }
}
@supports not (aspect-ratio: 439 / 548) {
  ._13zlznlf {
    padding-top: calc(548/439 * 100%);
  }
}