.ov5r4r1 {
  background-color: var(--_18bwoz22);
  color: var(--_18bwoz21);
}
.ov5r4r2 {
  background-color: var(--_18bwoz24);
  color: var(--_18bwoz21);
}
.ov5r4r3 {
  background-color: var(--_18bwoz27);
  color: var(--_18bwoz20);
}
.ov5r4r4 {
  background-color: var(--_18bwoz20);
  color: var(--_18bwoz21);
}
.ov5r4r5 {
  background-color: transparent;
}
.ov5r4r1 .u3zewj0 .button {
  border: 2px solid var(--_18bwoz21);
}
.ov5r4r2 .u3zewj0 .button {
  border: 2px solid var(--_18bwoz21);
}
.ov5r4r4 .u3zewj0 .button {
  border: 2px solid var(--_18bwoz21);
}