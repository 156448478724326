.icudjj0 {
  position: relative;
}
.icudjj1 {
  aspect-ratio: 1440 / 800;
  display: block;
  max-height: 800px;
  width: 100%;
}
.icudjj1 ._1csod971 {
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.icudjj2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: var(--current-width);
}
.icudjj3 {
  color: var(--_18bwoz21);
  text-align: center;
}
.icudjj6 {
  align-items: center;
  grid-column: span var(--grid-column-count);
  padding-bottom: 40px;
  margin: 0;
}
.icudjj7 {
  bottom: 0;
  left: 0;
  padding-bottom: 50px;
  position: absolute;
  right: 0;
}
.icudjj8 {
  grid-column: span 12;
}
.icudjj8 ._1csod971 {
  width: 100%;
  height: auto;
}
.icudjjb {
  grid-column: span 4;
}
.icudjjb ._1csod971 {
  width: 100%;
  height: auto;
}
@media (max-width: 1023px) {
  .icudjj1 {
    position: relative;
  }
  .icudjj2 {
    width: calc(100% - 30px);
    margin-inline: 15px;
  }
}
@media (max-width: 767px) {
  .icudjj0 {
    margin-bottom: 3rem;
  }
  .icudjj2 {
    position: static;
    padding-top: 40px;
    transform: unset;
  }
  .icudjj3 {
    color: var(--_18bwoz20);
    padding-bottom: 40px ;
  }
  .icudjj7 {
    padding-bottom: 0;
    position: static;
  }
  .icudjj9 {
    display: none;
  }
  .icudjja ._1csod971 {
    filter: invert(100%);
  }
}
@media (min-width: 768px) {
  .icudjj1:after {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(12, 30, 48, 0.2);
    background-blend-mode: multiply;
    position: absolute;
    top: 0;
    left: 0;
  }
  .icudjjb {
    display: none;
  }
}
@supports not (aspect-ratio: 1440 / 800) {
  .icudjj1 {
    height: 100%;
    padding-top: calc(800/1440 * 100%);
  }
}