._48ch6u0 {
  display: flex;
  flex-direction: row;
  background: transparent;
}
._48ch6u1 {
  height: 0;
}
._48ch6u2 {
  height: 25px;
}
._48ch6u3 {
  height: 50px;
}
._48ch6u4 {
  height: 75px;
}
._48ch6u5 {
  height: 100px;
}
._48ch6u6 {
  height: 125px;
}
@media (max-width: 1023px) {
  ._48ch6u1 {
    height: 0;
  }
  ._48ch6u2 {
    height: 10px;
  }
  ._48ch6u3 {
    height: 20px;
  }
  ._48ch6u4 {
    height: 30px;
  }
  ._48ch6u5 {
    height: 40px;
  }
  ._48ch6u6 {
    height: 50px;
  }
}