.sb9mpa0 {
  background: var(--_18bwoz24);
  color: var(--_18bwoz21);
}
.sb9mpa1 {
  padding: 3rem 0 6rem;
}
.sb9mpa2 {
  padding-bottom: 2rem;
  text-align: center;
}
.sb9mpa3 {
  font-family: NunitoSans-Light;
  font-size: var(--_1ttbmbvo);
  line-height: var(--_1ttbmbv1d);
}
.sb9mpa4 {
  display: inline;
  font: inherit;
}
.sb9mpa5:last-child {
  margin-right: 0;
}
.sb9mpa6 {
  grid-column: span 4;
  display: flex;
  flex-direction: column;
  gap: 2.75rem;
  padding-bottom: 3rem;
}
.sb9mpa7 {
  width: 205px;
  height: 18px;
  fill: var(--_18bwoz2b);
}
.sb9mpa8 {
  --grid-column-count: 8;
  grid-column: 5 / span 8;
  grid-gap: calc(var(--grid-gap) * 2) var(--grid-gap);
}
@media (min-width: 1440px) {
  .sb9mpa5 {
    margin-left: 2.22rem;
  }
}
@media (max-width: 1439px) {
  .sb9mpa4 {
    display: block;
    margin-bottom: 1rem;
  }
  .sb9mpa5 {
    margin-right: 1.5rem;
  }
}
@media (max-width: 479px) {
  .sb9mpa5 {
    margin-right: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .sb9mpa6 {
    grid-column: span 12;
  }
  .sb9mpa8 {
    grid-column: 3 / span 8;
  }
}
@media (max-width: 1023px) {
  .sb9mpa6 {
    align-items: center;
  }
}
@media (max-width: 767px) {
  .sb9mpa8 {
    grid-column: span 4;
  }
}