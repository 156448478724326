.pdrrbx0 {
  grid-column: span 3;
}
.pdrrbx1 {
  list-style: none;
  margin: 0;
  padding: 0;
}
.pdrrbx2 {
  padding-block: 10px;
}