._1hf8ml70 {
  --container-width: 1360px;
  --full-width-minus-width: calc(100vw - var(--container-width));
  --container-min-margin: max(calc(calc(100vw - 1360px) / 2), 60px);
  --container-margin: max(calc(var(--full-width-minus-width) / 2), var(--container-min-margin));
  --current-width: calc(100vw - (var(--container-margin) * 2));
  margin-left: max(calc(calc(100vw - 1360px) / 2), 60px);
  margin-right: max(calc(calc(100vw - 1360px) / 2), 60px);
  max-width: 1360px;
}
._1hf8ml71 {
  --grid-column-count: 12;
  --grid-gap: 20px;
  --grid-column-width: calc(
            (var(--current-width) - ((var(--grid-column-count) - 1) * var(--grid-gap))) /
            var(--grid-column-count)
        );
  display: grid;
  grid-template-columns: repeat(var(--grid-column-count), minmax(0, 1fr));
  gap: var(--grid-gap);
}
._1hf8ml73 {
  grid-row-gap: unset;
}
._1hf8ml75 {
  grid-column: 2 / span 10;
}
._1hf8ml76 {
  --grid-column-count: 10;
  grid-row-gap: unset;
}
._1hf8ml78 {
  grid-column: 2 / span 8;
}
@media (max-width: 1023px) {
  ._1hf8ml70 {
    margin-left: max(calc(calc(100vw - 1360px) / 2), 40px);
    margin-right: max(calc(calc(100vw - 1360px) / 2), 40px);
  }
  ._1hf8ml71 {
    --grid-gap: 15px;
  }
}
@media (max-width: 767px) {
  ._1hf8ml70 {
    margin-left: max(calc(calc(100vw - 1360px) / 2), 15px);
    margin-right: max(calc(calc(100vw - 1360px) / 2), 15px);
  }
  ._1hf8ml71 {
    --grid-column-count: 4;
  }
  ._1hf8ml75 {
    grid-column: span 4;
  }
  ._1hf8ml76 {
    display: block;
  }
}