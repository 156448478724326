._9pfk8c0 {
  background-color: var(--_18bwoz25);
  color: var(--_18bwoz21);
  text-align: center;
  padding: 6.25rem 0;
}
._9pfk8c1 {
  background-color: transparent;
  color: var(--_18bwoz20);
  padding: 0;
  margin-block: 6.25rem;
}
._9pfk8c4 {
  color: inherit;
  text-align: inherit;
}
._9pfk8c1 ._9pfk8c5 {
  font-family: var(--_1ttbmbv7);
  font-size: var(--_1ttbmbvj);
  line-height: var(--_1ttbmbv18);
  letter-spacing: var(--_1ttbmbv1y);
}
._9pfk8c6 {
  margin: 0;
}
._9pfk8c7 {
  margin-bottom: 10px;
}
._9pfk8c8 {
  padding: 0;
}
._9pfk8c8 .wjlq0t3 {
  margin: 0;
}
._9pfk8c9 {
  --grid-column-count: 4;
  --grid-row-gap: 40px;
  grid-column: 1 / -1;
  grid-row-gap: var(--grid-row-gap);
  grid-auto-flow: row;
  margin-top: 70px;
}
._9pfk8c1 ._9pfk8c9 {
  --grid-row-gap: 45px;
  margin-top: 40px;
}
._9pfk8ca {
  margin: 0;
}
._9pfk8cb {
  padding: 0 var(--grid-gap);
  text-align: left;
}
._9pfk8ca ._9pfk8cb {
  border-top: 1px solid var(--_18bwoz26);
  padding: 45px 0 0;
}
@media (max-width: 767px) {
  ._9pfk8c0 {
    padding-block: 3rem;
  }
  ._9pfk8c1 {
    margin-block: 3rem;
  }
  ._9pfk8c9 {
    --grid-column-count: 2;
  }
}
@media (min-width: 768px) {
  ._9pfk8c6 {
    font-family: var(--_1ttbmbv0);
    font-size: var(--_1ttbmbvc);
    line-height: var(--_1ttbmbv11);
    letter-spacing: var(--_1ttbmbv1r);
  }
  ._9pfk8c1 ._9pfk8c6 {
    font-family: var(--_1ttbmbv2);
    font-size: var(--_1ttbmbve);
    line-height: var(--_1ttbmbv13);
    letter-spacing: var(--_1ttbmbv1t);
    margin-bottom: 10px;
  }
}
@media (max-width: 479px) {
  ._9pfk8c9 {
    --grid-column-count: 1;
  }
  ._9pfk8cb {
    padding: 0;
  }
}