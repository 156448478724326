._2efcpd0 ._165qga30 {
  border: none;
  padding-bottom: 100px;
  margin-top: 100px;
}
._2efcpd0 ._165qga31 {
  grid-column: 1 / -1;
}
._2efcpd0 ._165qga33 {
  font-family: var(--_1ttbmbv2);
  font-size: var(--_1ttbmbve);
  font-weight: var(--_1ttbmbv25);
  line-height: var(--_1ttbmbv13);
  letter-spacing: var(--_1ttbmbv1t);
  text-transform: var(--_1ttbmbv2h);
  margin: 0 0 8px;
}
._2efcpd0 ._165qga33 {
  margin-bottom: 20px;
  text-align: center;
}
._2efcpd0 .viw56u1 {
  margin-bottom: 0;
}
._2efcpd0 .viw56u1:first-child {
  grid-area: a1;
}
._2efcpd0 .viw56u1:nth-child(2) {
  grid-area: a2;
}
._2efcpd0 .viw56u1:nth-child(3) {
  grid-area: b;
}
._2efcpd0 .viw56u1:nth-child(4) {
  grid-area: c;
}
._2efcpd0 ._165qga32 {
  display: grid;
  gap: var(--grid-gap);
  grid-column: 2 / span 10;
  grid-template-areas: 'a1 b c' 'a2 b c';
  grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 1023px) {
  ._2efcpd0 ._165qga33 {
    font-size: var(--_1ttbmbvr);
    line-height: var(--_1ttbmbv1g);
  }
}
@media (max-width: 767px) {
  ._2efcpd0 ._165qga33 {
    text-align: left;
  }
  ._2efcpd0 .viw56u1 {
    margin-bottom: 20px;
  }
  ._2efcpd0 ._165qga32 {
    display: block;
    grid-column: 1 / -1;
  }
}