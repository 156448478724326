.wjlq0t0 {
  padding: 3rem 1vw;
  text-align: left;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.wjlq0t3 {
  margin-top: 1.111rem;
}
.wjlq0t3 > *:not(:last-child) {
  margin-right: 2rem;
}
:where(.wjlq0t2 a) {
  display: inline-block;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
}
.wjlq0t2 a {
  height: auto;
  padding-top: 1em;
  padding-bottom: 1em;
  font-family: Nexa-Bold;
  font-size: calc(1em - 2px);
  font-weight: 600;
  letter-spacing: 0.2em;
  line-height: 1.75em;
  text-transform: uppercase;
  padding: 0;
  text-decoration: underline;
  transition: opacity 0.2s;
}
.wjlq0t2 a:hover {
  opacity: 0.8;
}
.wjlq0t2 .richtext-image {
  max-width: 100%;
  height: auto;
}
.wjlq0t2 .richtext-image.full-width {
  width: 100%;
}
.wjlq0t2 .richtext-image.left {
  float: left;
}
.wjlq0t2 .richtext-image.left ~ * {
  clear: both;
}
.wjlq0t2 .richtext-image.right {
  float: right;
}
.wjlq0t2 .richtext-image.right ~ * {
  clear: both;
}
.wjlq0t2 p {
  min-height: 27px;
}
.wjlq0t4 {
  text-align: center;
}
.wjlq0t5 {
  text-align: right;
}