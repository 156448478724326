._1kzblvh0 {
  margin-block: 6.25rem;
}
._1kzblvh1 {
  padding-bottom: 1.25rem;
  text-align: center;
}
._1kzblvh4 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-column: 1 / -1;
}
._1kzblvh5 {
  margin: 0 1rem;
}
._1kzblvh6 {
  align-items: center;
  flex-wrap: wrap;
  display: flex;
}
._1kzblvh7 {
  width: 100%;
  height: auto;
}
._1kzblvh8 {
  height: auto;
  object-fit: cover;
  width: 100%;
}
._1kzblvh9 {
  width: 100%;
}
._1kzblvha {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  gap: calc(var(--grid-gap) / 2);
  padding: 6.25rem 0 0;
  overflow: hidden;
  width: 100%;
}
._1kzblvhb:first-child {
  grid-column: 1 / span 6;
}
._1kzblvhb:nth-child(2) {
  grid-column: 8 / span 6;
}
._1kzblvhb:last-child {
  grid-column: 15 / span 6;
}
@media (max-width: 1023px) {
  ._1kzblvh2 {
    font-size: var(--_1ttbmbvq);
  }
}
@media (max-width: 767px) {
  ._1kzblvh0 {
    margin-block: 3rem;
  }
  ._1kzblvh2 {
    font-size: var(--_1ttbmbvw);
  }
  ._1kzblvha {
    display: block;
    padding: 1.875rem 0 0;
  }
  ._1kzblvhb {
    padding: 0 0 2rem;
    text-align: center;
    width: 100%;
  }
  ._1kzblvhb:last-child {
    padding: 0;
  }
}