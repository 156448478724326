.ityi0e1 {
  display: block;
  height: calc(100vh - 48px);
  position: relative;
  width: 100%;
}
.ityi0e2 {
  aspect-ratio: 1440 / 718;
  height: unset;
}
.ityi0e3 {
  display: block;
}
.ityi0e4 {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
@media (max-width: 1023px) {
  .ityi0e1 {
    height: auto;
  }
  .ityi0e2 {
    height: unset;
  }
}
@supports not (aspect-ratio: 1440 / 718) {
  .ityi0e2 {
    height: 100%;
    padding-top: calc(718/1440 * 100%);
  }
}
@media (min-width: 1024px) {
  .ityi0e3 {
    bottom: 0;
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    right: 0;
    width: 100%;
  }
}
@media (max-width: 479px) {
  .ityi0e4 {
    flex-direction: column;
  }
}