._1srrrin1 {
  display: flex;
  align-items: stretch;
  min-height: 500px;
}
._1srrrin2 {
  position: relative;
  width: 50%;
  flex-shrink: 0;
  overflow: hidden;
}
._1srrrin3 {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
._1srrrin3 ._1csod971 {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
._1srrrin5 {
  flex-shrink: 0;
  padding: 2.1875rem;
}
._1srrrin6 {
  align-items: center;
}
._1srrrin7 {
  display: block;
  max-width: 370px;
  margin-bottom: 2.875rem;
}
._1srrrin8 {
  padding: 0;
}
._1srrrin7 ._1csod971 {
  display: block;
  max-height: 50px;
}
._1srrrin8 .wjlq0t3 {
  margin-top: 20px;
}
._1srrrin9 {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}
._1srrrina {
  justify-content: center;
}
._1srrrinb {
  justify-content: flex-end;
}
._1srrrinc {
  height: 3rem;
  width: auto;
}
@media (min-width: 1024px) {
  ._1srrrin0 ._1srrrin4 {
    flex-direction: row-reverse;
  }
  ._1srrrin3 ._1csod971 {
    height: 100%;
  }
  ._1srrrin5 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50%;
  }
}
@media (max-width: 1023px) {
  ._1srrrin1 {
    flex-direction: column;
  }
  ._1srrrin2 {
    width: 100%;
    height: 0;
    padding-bottom: 75%;
  }
  ._1srrrin5 {
    width: 100%;
    padding-inline: 0;
    background-color: transparent;
    color: var(--_18bwoz20);
  }
}
@media (max-width: 767px) {
  ._1srrrin7 {
    margin-bottom: 1rem;
  }
  ._1srrrinc {
    height: 2.5rem;
  }
}
@media (min-width: 768px) {
  ._1srrrin8 .wjlq0t1 {
    font-size: var(--_1ttbmbve);
    line-height: var(--_1ttbmbv13);
  }
}