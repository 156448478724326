._9kq9ya0 {
  display: inline-block;
  line-height: 32px;
  margin-bottom: 20px;
}
._9kq9ya1 {
  margin-bottom: 20px;
}
._9kq9ya1 ._1mn7yhj5 {
  fill: var(--_18bwoz2c);
}