._1h5djn30 {
  grid-column: span 2;
  font-size: var(--_1ttbmbvn);
}
._1h5djn31 {
  margin-bottom: 1.2rem;
}
._1h5djn32 {
  margin-bottom: 1rem;
}
@media (max-width: 1439px) {
  ._1h5djn30 {
    grid-column: span 4;
  }
}