.viw56u1 {
  margin-bottom: 40px;
}
.viw56u1:last-child {
  margin-bottom: 0;
}
.viw56u2 {
  margin: 0;
}
.viw56u3.u3zewj0 * {
  color: inherit;
  font-family: var(--_1ttbmbv7);
  font-size: var(--_1ttbmbvj);
  line-height: var(--_1ttbmbv18);
  text-decoration: none;
}
@media (max-width: 767px) {
  .viw56u1 {
    margin-bottom: 20px;
  }
  .viw56u1:last-child {
    margin-bottom: 0;
  }
}