.basvci0 {
  background-color: var(--_18bwoz23);
}
.basvci2 {
  color: var(--_18bwoz21);
  grid-column: 3 / span 8;
  padding: 100px 0;
}
.basvci3 {
  display: flex;
  gap: var(--grid-gap);
  margin-bottom: 40px;
}
.basvci2 .yjwwhq4 {
  display: block;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .basvci2 {
    grid-column: 1 / -1;
  }
}