.yls3ux0 {
  position: sticky;
  top: 0;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  z-index: 1;
  height: 48px;
}
.yls3ux1 {
  height: 42px;
  margin: 0;
}
.yls3ux2 {
  position: relative;
  height: 48px;
}
.yls3ux3 {
  height: 42px;
  margin: 0;
}
.yls3ux5 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.yls3ux6 {
  display: none;
  top: 42px;
  height: calc(100vh - 42px);
  background-color: var(--_18bwoz21);
  color: var(--_18bwoz20);
}
.yls3ux7.yls3ux6 {
  display: block;
}
.yls3ux8 {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 0.6;
  z-index: 3;
}
.yls3ux9 {
  padding: 15px;
}
.yls3uxa {
  padding: 13.5px 15px;
}
.yls3uxb {
  display: block;
}
.yls3uxc {
  width: 15px;
  height: 12px;
}
.yls3uxd {
  width: 15px;
  height: 15px;
}
.yls3uxe {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  background-color: var(--_18bwoz22);
  color: var(--_18bwoz21);
  font-size: 14px;
  transition: background-color 0.5s;
  padding-inline: 1.5em;
}
.yls3uxe:hover {
  background-color: var(--_18bwoz24);
}
.yls3uxf {
  font-size: 12px;
  background: transparent;
  padding-left: 0;
  padding-right: 10px;
  color: var(--_18bwoz22);
  text-decoration: underline;
  line-height: 1.7em;
  letter-spacing: 0.15em;
}
.yls3uxf:hover {
  background-color: transparent;
  opacity: 0.8;
}
.yls3uxg {
  overflow: hidden;
}