:where(.yjwwhq0) {
  display: inline-block;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
}
.yjwwhq1 {
  cursor: default;
  pointer-events: none;
}
.yjwwhq2:disabled {
  cursor: default;
  pointer-events: none;
}
:where(.yjwwhq2) {
  display: inline-block;
  appearance: none;
  padding: 0;
  border: 0;
  background: transparent;
  color: inherit;
  font-family: inherit;
  font-size: 1em;
  line-height: inherit;
  cursor: pointer;
}
.yjwwhq3 {
  height: 3.5em;
  padding-top: 1em;
  padding-bottom: 1em;
  font-family: Nexa-Bold;
  font-size: calc(1em - 2px);
  font-weight: 600;
  letter-spacing: 0.2em;
  line-height: 1.75em;
  text-transform: uppercase;
}
.yjwwhq4 {
  display: inline-block;
  position: relative;
  padding-left: 2.25em;
  padding-right: 2.25em;
  color: var(--_18bwoz21);
  cursor: pointer;
  text-align: center;
  transition: color 1s;
  z-index: 0;
}
.yjwwhq4:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: clip-path 0.5s, background-color 1s;
  clip-path: polygon( 0% 1.25em, 1.25em 0%, 100% 0%, 100% calc(100% - 1.25em), calc(100% - 1.25em) 100%, 0% 100%);
  background-color: var(--_18bwoz22);
}
.yjwwhq4:hover:before {
  clip-path: polygon(0% 1.875em, 1.875em 0%, 100% 0%, 100% calc(100% - 1.875em), calc(100% - 1.875em) 100%, 0% 100%);
  background-color: var(--_18bwoz24);
}
.yjwwhq4:disabled:before {
  background-color: var(--_18bwoz27);
}
.yjwwhq4.yjwwhq1:before {
  background: var(--_18bwoz27);
}
.ov5r4r1 .yjwwhq4 {
  color: var(--_18bwoz2b);
  filter: drop-shadow(1px 0 0 var(--_18bwoz2b)) drop-shadow(0 1px 0 var(--_18bwoz2b)) drop-shadow(-1px 0 0 var(--_18bwoz2b)) drop-shadow(0 -1px 0 var(--_18bwoz2b));
}
.ov5r4r1 .yjwwhq4:before {
  background-color: var(--_18bwoz22);
}
.ov5r4r1 .yjwwhq4:hover {
  color: var(--_18bwoz20);
}
.ov5r4r1 .yjwwhq4:hover:before {
  background-color: var(--_18bwoz2b);
}
.yjwwhq5 {
  width: 1em;
  height: 1em;
  margin-left: 0.625em;
  transform: translateY(2px);
  fill: currentcolor;
}
.yjwwhq6 {
  padding-right: 0.7em;
}
.yjwwhq6:disabled {
  color: var(--_18bwoz27);
}
.yjwwhq6.yjwwhq1 {
  color: var(--_18bwoz27);
}
.yjwwhq6 svg {
  width: 1em;
  height: 0.75em;
  margin-left: 0.625em;
  transform: translateX(0);
  transition: transform 0.2s;
  fill: currentcolor;
}
.yjwwhq6:hover svg {
  transform: translateX(0.625em);
}
.yjwwhq7 {
  height: auto;
  padding: 0;
  text-decoration: underline;
  transition: opacity 0.2s;
}
.yjwwhq7:hover {
  opacity: 0.8;
}
.yjwwhq7:disabled {
  color: var(--_18bwoz27);
}
.yjwwhq7.yjwwhq1 {
  color: var(--_18bwoz27);
}