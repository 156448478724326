.ozyurm0 {
  --grid-column-count: 3;
  --grid-gap: 20px;
  border-top: 1px solid var(--_18bwoz26);
  border-bottom: 1px solid var(--_18bwoz26);
  padding-top: 80px;
  padding-bottom: 80px;
}
.ozyurm1 {
  display: grid;
  grid-template-columns: repeat(var(--grid-column-count), 1fr);
  column-gap: var(--grid-gap);
  row-gap: 80px;
  margin-bottom: 80px;
}
.ozyurm2 {
  display: flex;
  flex-direction: column;
}
.ozyurm3 {
  aspect-ratio: 483 / 350;
  display: block;
  position: relative;
  margin-bottom: 27px;
  width: 100%;
}
.ozyurm3 ._1csod971 {
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.ozyurm4 {
  color: var(--_18bwoz22);
  margin-bottom: 10px;
}
.ozyurm5 {
  margin-bottom: 10px;
}
.ozyurm7 {
  text-align: center;
}
@media (max-width: 1023px) {
  .ozyurm0 {
    --grid-gap: 15px;
  }
}
@media (max-width: 767px) {
  .ozyurm0 {
    --grid-column-count: 2;
  }
  .ozyurm1 {
    row-gap: 40px;
    margin-bottom: 40px;
  }
}
@supports not (aspect-ratio: 483 / 350) {
  .ozyurm3 {
    height: 100%;
    padding-top: calc(350/483 * 100%);
  }
}